import styles from './contact.module.css'

function SocialAspect({ id }) {

    switch (id) {
        case 0:
            return (
                <div style={{ height: '100%'}}>
                    <svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="30px" height="100%">    <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z" /></svg>
                </div>

            );
        case 1:
            return (
                <div style={{ height: '100%'}}>
                    <svg width="30px" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path fill-rule="evenodd" clip-rule="evenodd" d="M48.854 0C21.839 0 0 22 0 49.217c0 21.756 13.993 40.172 33.405 46.69 2.427.49 3.316-1.059 3.316-2.362 0-1.141-.08-5.052-.08-9.127-13.59 2.934-16.42-5.867-16.42-5.867-2.184-5.704-5.42-7.17-5.42-7.17-4.448-3.015.324-3.015.324-3.015 4.934.326 7.523 5.052 7.523 5.052 4.367 7.496 11.404 5.378 14.235 4.074.404-3.178 1.699-5.378 3.074-6.6-10.839-1.141-22.243-5.378-22.243-24.283 0-5.378 1.94-9.778 5.014-13.2-.485-1.222-2.184-6.275.486-13.038 0 0 4.125-1.304 13.426 5.052a46.97 46.97 0 0 1 12.214-1.63c4.125 0 8.33.571 12.213 1.63 9.302-6.356 13.427-5.052 13.427-5.052 2.67 6.763.97 11.816.485 13.038 3.155 3.422 5.015 7.822 5.015 13.2 0 18.905-11.404 23.06-22.324 24.283 1.78 1.548 3.316 4.481 3.316 9.126 0 6.6-.08 11.897-.08 13.526 0 1.304.89 2.853 3.316 2.364 19.412-6.52 33.405-24.935 33.405-46.691C97.707 22 75.788 0 48.854 0z" fill="#fff" /></svg>
                </div>

            );
        case 2:
            return (
                <div style={{ height: '100%'}}>
                    <svg width="30px" height="100%" viewBox="0 0 67 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M-0.000106267 5.87656V4.16667C-0.000106267 1.86548 1.8747 0 4.18739 0H62.8124C65.1251 0 66.9999 1.86548 66.9999 4.16667V5.87656L33.4999 26.7099L-0.000106267 5.87656ZM34.6096 30.9339C33.9305 31.3556 33.0693 31.3556 32.3902 30.9339L-0.000106267 10.7901V45.8333C-0.000106267 48.1345 1.8747 50 4.18739 50H62.8124C65.1251 50 66.9999 48.1345 66.9999 45.8333V10.7901L34.6096 30.9339Z" fill="white" />
                    </svg>
                </div>

            );
        case 3:
            return (
                <div style={{ height: '100%'}}>
                    <svg width="30px" height="100%" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.32143 42.6773C17.0853 52.4409 32.9132 52.4409 42.6771 42.6773C52.441 32.9137 52.441 17.0863 42.6771 7.32271C32.9132 -2.4409 17.0853 -2.4409 7.32143 7.32271C-2.44048 17.0863 -2.44048 32.9137 7.32143 42.6773ZM18.4113 9.45262L22.1313 13.1725C22.8753 13.9164 23.0333 15.0664 22.5173 15.9844L21.1193 18.4663C20.7743 19.0779 20.8798 19.8446 21.3773 20.3402L29.6592 28.6219C30.1553 29.1187 30.922 29.2234 31.5332 28.8778L34.0152 27.4799C34.9328 26.9627 36.0828 27.1205 36.8271 27.8659L40.5491 31.5877C41.4645 32.505 41.4645 33.9903 40.5491 34.9076L38.7871 36.6695C36.7774 38.6744 33.6713 39.0788 31.2152 37.6555L31.0472 37.5595C23.7454 33.3874 17.6382 27.4092 13.3114 20.1982L12.4654 18.7862C10.9858 16.3245 11.3726 13.1716 13.4034 11.1406L15.0913 9.45262C15.5314 9.01199 16.1286 8.76439 16.7513 8.76439C17.3741 8.76439 17.9713 9.01199 18.4113 9.45262Z" fill="white" />
                    </svg>
                </div>

            );

    }

}

export default SocialAspect;