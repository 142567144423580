import React from "react";

function E404() {

    return (
        <div style={{width:'100%', height:'100svh',textAlign:'center',justifyContent:'center', display:'flex', fontWeight:'bold', fontSize:'30vw', alignItems:'center', color:'white', fontFamily:'SF Mono'}}>
            <p style={{margin:'0', width:'100%', height:'100svh',textAlign:'center',justifyContent:'center', display:'flex', alignItems:'center'}}>404!</p>
        </div>
    );

}

export default E404;