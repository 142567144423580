import React from 'react';
import styles from './footer.module.css'
import { useEffect, useRef } from 'react';

function Footer() {

    return (

        <div className={styles.master}>
            <p>cmhenville.tech</p>
            <p>v1.6.2</p>
            {/* <div className={styles.svg}>
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 2732.000000 1118.000000"
                    preserveAspectRatio="xMidYMid meet">
                    <g transform="translate(0.000000,1118.000000) scale(0.100000,-0.100000)" fill="#FFFFFF" stroke="none">
                        <path d="M9858 10667 c-125 -53 -293 -241 -699 -782 -402 -536 -661 -929 -920
                    -1395 -153 -276 -318 -507 -1375 -1925 -226 -302 -511 -687 -634 -855 -256
-350 -425 -573 -589 -780 -140 -176 -282 -342 -288 -336 -6 6 68 224 128 373
237 594 651 1345 971 1760 43 56 89 123 103 150 96 185 18 411 -173 505 -60
30 -75 33 -157 33 -147 0 -225 -45 -342 -199 -450 -590 -936 -1139 -1509
-1706 -666 -658 -1106 -1016 -1479 -1202 -219 -110 -440 -147 -544 -93 -53 27
-134 114 -165 176 -72 147 -93 467 -47 714 110 600 421 1340 762 1817 306 426
685 769 1044 944 190 92 360 135 539 135 200 0 329 -69 463 -246 53 -69 155
-275 208 -420 49 -131 54 -189 25 -283 -21 -71 -22 -75 -5 -117 30 -80 106
-120 184 -97 56 17 88 58 126 164 85 239 90 434 19 720 -125 503 -417 812
-854 904 -116 25 -374 25 -524 0 -594 -97 -1215 -510 -1694 -1125 -406 -521
-738 -1221 -917 -1931 -194 -772 -203 -1266 -27 -1615 169 -338 473 -557 839
-606 118 -16 322 -6 452 21 465 97 989 424 1603 1002 71 66 128 117 128 114 0
-2 -7 -36 -15 -74 -55 -261 -62 -483 -21 -622 42 -140 138 -262 253 -323 94
-48 156 -61 272 -55 230 12 434 130 721 418 280 280 631 719 1075 1343 76 108
140 195 142 194 1 -2 -18 -66 -43 -142 -66 -204 -195 -633 -253 -840 -175
-629 -473 -1947 -508 -2244 -36 -313 6 -575 119 -749 86 -132 245 -237 358
-236 100 0 175 59 201 157 23 86 -13 170 -97 228 -129 88 -180 273 -191 684
-11 418 35 703 210 1310 218 755 486 1436 977 2480 674 1435 1366 2583 2262
3750 93 121 196 252 228 290 94 111 123 173 128 275 5 113 -20 183 -92 255
-72 72 -131 97 -236 102 -72 3 -93 0 -142 -20z"/>
                        <path d="M21825 8757 c-64 -21 -97 -42 -176 -108 -86 -72 -143 -134 -226 -250
-154 -214 -419 -640 -578 -929 -46 -84 -135 -213 -271 -395 -390 -521 -573
-719 -1189 -1285 -104 -96 -349 -323 -544 -505 -376 -351 -482 -447 -488 -441
-9 9 44 215 92 361 87 263 132 361 349 750 78 140 100 210 100 315 0 71 -5 95
-28 147 -74 162 -239 226 -447 174 -209 -52 -417 -190 -669 -441 -150 -149
-261 -275 -520 -590 -106 -129 -243 -293 -304 -365 l-111 -129 -3 59 c-8 143
32 433 103 739 26 113 45 220 45 253 0 223 -280 355 -451 213 -21 -16 -77 -87
-125 -157 -146 -210 -279 -333 -894 -828 -135 -108 -285 -224 -335 -257 -87
-58 -90 -60 -93 -37 -2 13 18 134 43 269 74 399 87 499 83 635 -4 105 -8 128
-32 182 -32 69 -88 128 -158 164 -70 36 -195 39 -300 5 -108 -33 -222 -99
-355 -204 -128 -100 -459 -430 -667 -664 -82 -93 -151 -166 -153 -164 -2 2 3
48 11 102 46 314 54 541 22 637 -58 176 -232 289 -403 260 -187 -32 -332 -158
-764 -659 -264 -306 -506 -569 -438 -475 315 432 411 777 289 1036 -93 197
-311 294 -522 232 -73 -22 -192 -102 -259 -175 -201 -219 -442 -639 -539 -937
-12 -38 -30 -77 -39 -87 -17 -17 -288 -171 -409 -232 -60 -30 -73 -34 -68 -19
29 98 70 223 133 408 94 280 124 397 130 511 10 164 -44 269 -170 333 -57 29
-77 34 -139 35 -108 0 -178 -35 -373 -188 -220 -172 -449 -375 -809 -715 -345
-326 -401 -377 -468 -427 -34 -24 -62 -43 -64 -42 -7 8 27 153 37 157 29 9
286 187 379 262 196 157 414 381 606 624 469 592 695 1136 694 1670 0 66 -7
150 -14 186 -30 138 -112 251 -224 306 -51 25 -68 28 -157 28 -90 0 -107 -3
-165 -30 -133 -61 -294 -200 -479 -413 -195 -225 -396 -497 -571 -772 -255
-402 -548 -998 -699 -1420 -28 -80 -55 -154 -60 -165 -6 -15 -36 -30 -102 -53
-130 -44 -289 -82 -341 -82 -59 0 -130 -45 -158 -99 -26 -51 -26 -132 -1 -186
51 -111 179 -158 398 -147 l93 4 0 -68 c1 -142 53 -293 138 -401 54 -68 156
-139 245 -171 65 -24 86 -27 212 -27 154 0 207 10 342 67 175 72 346 217 649
546 102 112 188 201 190 200 2 -2 -3 -40 -11 -83 -19 -104 -18 -244 0 -323 62
-263 307 -422 590 -383 118 17 270 72 475 174 102 50 188 92 191 92 4 0 28
-28 54 -63 93 -123 255 -227 405 -258 90 -19 256 -14 344 10 215 60 423 195
675 441 79 77 186 188 239 248 52 59 96 106 97 105 1 -2 -3 -37 -11 -78 -17
-98 -17 -251 0 -316 35 -133 131 -249 253 -307 65 -31 73 -32 193 -32 110 0
133 3 198 27 238 87 451 290 892 852 67 86 124 156 125 154 1 -2 -8 -55 -21
-118 -30 -141 -33 -322 -9 -415 41 -160 146 -297 280 -367 110 -57 173 -72
305 -72 143 1 244 25 385 94 158 77 239 142 592 482 l188 181 3 -44 c17 -259
85 -424 228 -558 112 -105 238 -155 389 -154 265 0 493 153 830 556 44 53 83
96 86 96 4 1 2 -20 -3 -46 -18 -86 -14 -310 6 -383 55 -202 197 -345 401 -406
80 -24 246 -24 335 0 137 37 317 143 487 290 37 32 210 192 383 355 432 407
564 529 790 735 l195 177 7 -133 c10 -209 57 -386 139 -524 44 -75 168 -200
241 -242 176 -104 410 -124 619 -55 243 80 559 342 844 701 45 56 154 201 241
322 87 120 160 218 161 216 2 -1 -2 -52 -8 -112 -17 -165 -6 -559 20 -710 37
-218 110 -440 198 -604 174 -323 368 -498 623 -561 152 -38 340 -11 507 75 73
37 219 135 310 208 l81 66 28 -39 c45 -66 149 -156 217 -188 132 -63 308 -81
464 -47 158 34 361 138 554 283 206 155 242 204 243 328 0 107 -53 194 -147
241 -84 43 -130 42 -312 -11 -95 -27 -202 -51 -263 -58 -117 -13 -112 -15
-137 67 -17 58 -35 29 155 248 240 277 592 747 718 956 104 174 140 326 108
452 -31 121 -99 205 -204 253 -68 31 -192 30 -287 -2 -188 -64 -357 -163 -577
-339 -347 -277 -446 -387 -567 -630 -86 -172 -148 -385 -166 -569 l-8 -73 -71
-67 c-151 -144 -338 -293 -418 -334 l-38 -19 -35 47 c-101 139 -185 371 -220
615 -58 398 -2 859 149 1236 168 417 497 880 981 1382 289 299 287 297 305
392 13 66 -7 151 -50 212 -62 90 -180 139 -287 117 -78 -15 -200 -83 -290
-159 -125 -107 -365 -344 -511 -507 -75 -82 -211 -224 -302 -315 -177 -177
-267 -277 -565 -629 -719 -850 -1423 -1578 -1626 -1682 -25 -12 -45 -17 -50
-12 -5 5 -16 43 -25 84 -48 223 55 703 241 1124 129 292 193 407 361 655 178
263 366 560 513 812 51 87 107 180 126 207 49 74 68 141 63 220 -9 124 -73
224 -180 281 -54 29 -75 34 -143 37 -48 2 -96 -3 -120 -10z m-12161 -1688
c-47 -133 -165 -359 -274 -523 -98 -148 -254 -356 -266 -356 -11 0 168 334
270 505 112 186 288 457 293 451 2 -2 -8 -36 -23 -77z"/>
                        <path d="M19134 8041 c-33 -16 -83 -48 -112 -72 -28 -24 -71 -61 -97 -83 -60
-50 -93 -100 -112 -172 -39 -152 39 -314 186 -383 48 -22 69 -26 151 -26 88 0
101 3 162 33 139 68 231 200 253 360 21 155 -75 326 -203 362 -72 20 -161 13
-228 -19z"/>
                    </g>
                </svg> */}
            {/* </div> */}

        </div>

    );

}

export default Footer;