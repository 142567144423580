import React, { useState } from 'react';
import { useEffect, useRef } from 'react';
import styles from './projects.module.css';

function Other() {

    const svgRef = useRef(null);
    const [isMobile, setMobile] = useState(false);

    useEffect(() => {
        const svg = svgRef.current;
        const paths = svg.querySelectorAll(`.${styles.hidden.split(' ')[0]}`);

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {

                    setMobile(window.innerWidth <= 800);
                    let delay = 0;
                    paths.forEach((path) => {
                        setTimeout(() => {
                            path.classList.remove(styles.hidden);
                            path.style.opacity = '1';
                        }, delay);

                        delay += 0.5;
                    });

                    const observerInterval = setInterval(() => {

                        const noww = performance.now() / 1000
                        const xparam = 1.9 * Math.sin(noww)
                        const yparam = 2.1 * Math.cos(noww)
                        paths.forEach((path) => {

                            path.setAttribute('r', (xparam * Math.cos(parseFloat(path.getAttribute('cx')) / 400) + yparam * Math.sin(parseFloat(path.getAttribute('cy')) / 150) + 5.5));

                        });
                    }, 100);

                    return () => {
                        clearInterval(observerInterval); // Clear the interval when the observer is disconnected
                    };

                }
                else {

                    setMobile(window.innerWidth <= 800);
                    paths.forEach((path) => {
                        path.classList.add(styles.hidden);
                        path.style.opacity = '0';
                    });
                    
                }
            });
        });

        observer.observe(svg);

        return () => {
            observer.unobserve(svg);
        };
    }, [svgRef]);

    return (
        <div className={styles.otherTitle}>

            {isMobile ?

                <svg ref={svgRef} viewBox="0 0 1654 754" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle className={styles.hidden} cx="792.455" cy="424.221" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1019.47" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1368.34" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="152.584" cy="394.689" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="349.466" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="349.466" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="93.5178" cy="379.922" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="438.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="881.051" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1604.6" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="635.545" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="821.987" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="334.701" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="354.987" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1093.3" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1093.3" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1560.3" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="576.48" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1338.8" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1338.8" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1300.03" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="152.584" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1589.83" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="561.715" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1344.33" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="408.531" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="783.207" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1102.55" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1013.95" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="635.545" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="916.104" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="650.312" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="857.039" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1093.3" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1235.44" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="399.285" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="319.935" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="910.584" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="167.35" cy="379.922" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="458.351" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="650.312" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1013.95" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1300.03" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1324.04" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="679.844" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1397.87" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="428.819" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="969.649" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="570.96" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="305.168" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="295.922" cy="63.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1471.7" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="827.506" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="394.689" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="424.221" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1087.78" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="753.674" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1560.3" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="340.221" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7531" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1412.64" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="369.754" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="561.715" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1338.8" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="630.026" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="379" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="724.143" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="275.635" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="408.531" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="821.987" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="468.52" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1300.03" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="709.375" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="886.57" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="620.779" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="954.883" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.66" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1338.8" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="620.779" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1122.83" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.649" cy="498.052" r="4.92208" transform="rotate(-90 211.649 498.052)" fill="white" />
                    <circle className={styles.hidden} cx="1117.31" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="871.805" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1309.27" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="768.442" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="738.908" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.9" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1501.23" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1137.6" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1324.04" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="511.895" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="606.013" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="783.207" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.96" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1383.1" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="585.727" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7531" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="399.285" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1049" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="989.936" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1226.2" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="453.753" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="718.623" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="260.869" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="325.455" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="526.661" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1619.36" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="866.284" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="718.623" cy="704.779" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="960.403" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="502.651" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="925.35" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1589.83" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="305.168" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="576.48" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="325.455" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="600.492" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="916.104" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.96" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="975.169" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="821.987" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="591.247" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="738.908" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="821.987" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1619.36" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1176.38" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="576.48" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="408.531" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1560.3" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="305.168" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="827.506" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="354.987" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="709.375" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.66" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="182.116" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="260.869" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1167.13" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="827.506" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1093.3" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="718.623" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="827.506" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1122.83" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1324.04" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="399.285" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="123.051" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.649" cy="453.753" r="4.92208" transform="rotate(-90 211.649 453.753)" fill="white" />
                    <circle className={styles.hidden} cx="305.168" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="945.637" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="369.754" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1560.3" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1211.43" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="438.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="428.819" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1309.27" cy="424.221" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1270.49" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1063.77" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1211.43" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1501.23" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="541.428" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="399.285" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="438.987" r="4.92208" transform="rotate(-90 196.883 438.987)" fill="white" />
                    <circle className={styles.hidden} cx="881.051" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="709.375" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1078.53" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="487.883" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="334.701" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="497.129" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="497.129" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="857.039" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1575.07" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.9" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="458.351" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="989.936" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="364.233" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1220.67" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="295.922" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="812.74" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1191.14" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="606.013" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="487.883" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="428.819" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="954.883" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="467.598" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1353.57" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="857.039" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="733.389" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1285.26" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1191.14" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1049" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1324.04" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1442.17" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1019.47" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1146.84" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1501.23" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="473.117" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="167.35" cy="394.689" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="984.416" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="748.155" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="152.584" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="541.428" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1093.3" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1604.6" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="659.559" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="989.936" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1034.23" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="379" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1132.08" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="718.623" cy="734.312" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="842.273" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1634.13" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="733.389" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="620.779" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="724.143" cy="63.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="591.247" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="498.052" r="4.92208" transform="rotate(-90 196.883 498.052)" fill="white" />
                    <circle className={styles.hidden} cx="1211.43" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="659.559" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1545.53" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="260.869" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1161.61" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1560.3" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="260.869" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="561.715" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="718.623" cy="690.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="738.908" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="620.779" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="532.182" cy="93.5197" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7531" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1575.07" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="473.117" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="709.375" cy="93.5197" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="467.598" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="989.936" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1324.04" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="487.883" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1043.48" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="414.053" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1501.23" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="394.689" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="606.013" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="354.987" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="379" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1309.27" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1279.74" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="497.129" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="393.766" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="709.375" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="748.155" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="866.284" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="871.805" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="354.987" cy="63.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1043.48" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1013.95" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="502.651" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="379" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="718.623" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.96" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="482.363" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1338.8" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="753.674" cy="63.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1324.04" cy="468.52" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1176.38" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="585.727" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="709.375" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="783.207" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="881.051" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1604.6" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="394.689" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.96" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="246.103" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1132.08" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1019.47" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1004.7" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="438.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="690.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1049" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="290.403" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="305.168" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="975.169" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="424.221" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="591.247" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="468.52" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="969.649" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1043.48" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="379.922" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1117.31" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="399.285" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="886.57" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="453.753" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="325.455" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1004.7" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="561.715" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1314.79" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="295.922" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="975.169" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="606.013" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="591.247" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="512.818" r="4.92208" transform="rotate(-90 196.883 512.818)" fill="white" />
                    <circle className={styles.hidden} cx="1634.13" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="871.805" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="295.922" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="724.143" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="246.103" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="620.779" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1516" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="797.975" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1285.26" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="940.116" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="719.546" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="650.312" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.73" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="733.389" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1191.14" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="152.584" cy="379.922" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="895.819" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1226.2" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="709.375" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="960.403" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1220.67" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1338.8" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="886.57" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="866.284" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1013.95" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="768.442" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1019.47" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="305.168" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1132.08" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="969.649" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="748.155" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="753.674" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="724.143" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="718.623" cy="749.078" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="310.688" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1589.83" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1368.34" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1226.2" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1575.07" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1058.25" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="827.506" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="866.284" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="748.155" cy="704.779" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="606.013" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="364.233" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="989.936" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="940.116" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="734.312" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1285.26" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1575.07" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="960.403" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="635.545" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="428.819" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="842.273" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="408.531" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="275.635" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="570.96" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="748.155" cy="719.546" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="644.792" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1471.7" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="93.5178" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1028.71" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1161.61" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1235.44" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="473.117" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1309.27" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="630.026" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="340.221" cy="93.5197" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1338.8" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="354.987" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1338.8" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1314.79" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1117.31" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1604.6" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1294.51" cy="468.52" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.66" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="797.975" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="167.35" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="945.637" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="394.689" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="704.779" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1368.34" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="768.442" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1294.51" cy="453.753" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="393.766" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="659.559" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="290.403" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="709.375" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="591.247" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="748.155" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1344.33" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="827.506" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1279.74" cy="453.753" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1530.77" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1619.36" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="842.273" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="369.754" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1013.95" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="925.35" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="541.428" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1019.47" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="738.908" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="423.298" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="821.987" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="753.674" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="975.169" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="541.428" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="659.559" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="364.233" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1117.31" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="644.792" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="591.247" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1501.23" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="423.298" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="821.987" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="152.584" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1619.36" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.73" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="152.584" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="886.57" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="895.819" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="246.103" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1034.23" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="999.182" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1575.07" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="467.598" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="325.455" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="679.844" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1285.26" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1300.03" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1324.04" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="960.403" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="399.285" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1456.93" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1004.7" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="783.207" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="424.221" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="108.284" cy="394.689" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="399.285" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="675.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="827.506" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="325.455" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="615.259" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.66" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="137.819" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1132.08" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="423.298" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="591.247" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1108.06" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="546.948" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="295.922" cy="93.5197" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1220.67" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="630.026" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="182.116" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="487.883" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="482.363" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1132.08" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="969.649" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="954.883" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="703.858" cy="719.546" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1176.38" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="246.103" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1108.06" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="925.35" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1471.7" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="665.077" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="940.116" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1270.49" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="615.259" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="768.442" cy="93.5197" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="606.013" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="940.116" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1589.83" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1049" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1300.03" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="93.5178" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="989.936" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1132.08" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="424.221" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="497.129" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="319.935" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1226.2" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="821.987" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1250.21" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="984.416" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7531" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="675.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7531" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="709.375" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="945.637" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1102.55" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="945.637" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="630.026" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="733.389" cy="749.078" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="108.284" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="738.908" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1167.13" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1530.77" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="821.987" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1338.8" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="167.35" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="895.819" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="606.013" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="325.455" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1087.78" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1013.95" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="438.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1146.84" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="674.324" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="167.35" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="334.701" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="576.48" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="703.858" cy="704.779" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7531" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="659.559" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="310.688" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="665.077" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="123.051" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="881.051" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1004.7" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="748.155" cy="734.312" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="650.312" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1102.55" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="635.545" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="871.805" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="453.753" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="561.715" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1619.36" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="443.584" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="526.661" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="857.039" cy="315.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1634.13" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="871.805" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1471.7" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="615.259" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1211.43" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1560.3" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="871.805" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="482.363" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1329.56" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="108.284" cy="379.922" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="738.908" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="487.883" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="325.455" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="635.545" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="585.727" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="954.883" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="438.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="724.143" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="635.545" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="290.403" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="960.403" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="910.584" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1063.77" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="969.649" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="556.195" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="502.651" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="881.051" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.73" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="423.298" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1545.53" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="984.416" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1575.07" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1146.84" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="310.688" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="969.649" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="408.531" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="591.247" cy="93.5197" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="910.584" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="305.168" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="394.689" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1235.44" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1589.83" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1078.53" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1004.7" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="570.96" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1589.83" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="606.013" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="167.35" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="556.195" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1530.77" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="502.651" cy="63.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1019.47" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1285.26" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1300.03" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="443.584" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="999.182" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="644.792" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1397.87" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="999.182" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="866.284" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1028.71" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="960.403" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="733.389" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="310.688" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1122.83" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="319.935" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1161.61" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1338.8" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1560.3" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1530.77" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="857.039" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="999.182" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="137.819" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="295.922" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="526.661" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="748.155" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="733.389" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1043.48" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="393.766" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1220.67" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="916.104" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="384.52" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1205.91" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="310.688" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="960.403" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1028.71" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="674.324" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="532.182" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1368.34" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1353.57" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="591.247" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1019.47" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="659.559" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="310.688" cy="63.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="886.57" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1013.95" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="871.805" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="334.701" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="886.57" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="556.195" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.649" cy="527.584" r="4.92208" transform="rotate(-90 211.649 527.584)" fill="white" />
                    <circle className={styles.hidden} cx="108.284" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="576.48" cy="93.5197" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="821.987" cy="424.221" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1191.14" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="768.442" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="384.52" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1575.07" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="606.013" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="895.819" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="182.116" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="960.403" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1309.27" cy="453.753" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="379" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="585.727" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="679.844" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="511.895" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="354.987" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="340.221" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="886.57" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="393.766" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="468.519" r="4.92208" transform="rotate(-90 196.883 468.519)" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="704.779" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="606.013" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="379" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1211.43" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1285.26" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="438.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1604.6" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.66" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1309.27" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1338.8" cy="468.52" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="393.766" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1412.64" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="502.651" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1604.6" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1324.04" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="954.883" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="310.688" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="650.312" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="570.96" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1013.95" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1211.43" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="768.442" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="458.351" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="275.635" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="275.635" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="384.52" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1191.14" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.9" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="305.168" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="644.792" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1619.36" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="526.661" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="821.987" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="123.051" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="975.169" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1619.36" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1530.77" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="940.116" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="945.637" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="487.883" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="842.273" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="984.416" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1530.77" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="502.651" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="753.674" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.96" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1078.53" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="999.182" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1486.47" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="753.674" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="290.403" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="895.819" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="260.869" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="325.455" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1545.53" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1314.79" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="340.221" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="123.051" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="895.819" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="738.908" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1324.04" cy="453.753" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="483.286" r="4.92208" transform="rotate(-90 196.883 483.286)" fill="white" />
                    <circle className={styles.hidden} cx="620.779" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1078.53" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="733.389" cy="704.779" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="354.987" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1063.77" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="600.492" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="797.975" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="325.455" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="310.688" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1117.31" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1383.1" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="497.129" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="910.584" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="334.701" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1501.23" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="369.754" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="487.883" cy="93.5197" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="428.819" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1102.55" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1205.91" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="453.753" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1220.67" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="325.455" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="753.674" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="709.375" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="954.883" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="511.895" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="925.35" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1063.77" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="137.819" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.73" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1078.53" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1176.38" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="182.116" cy="394.689" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1545.53" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1344.33" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="910.584" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="443.584" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="724.143" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="340.221" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1146.84" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="482.363" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="340.221" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="467.598" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1226.2" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1338.8" cy="424.221" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="364.233" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="561.715" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="556.195" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="511.895" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1205.91" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1146.84" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="473.117" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="940.116" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="674.324" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="414.053" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1102.55" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="768.442" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="108.284" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1338.8" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="901.338" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.9" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="502.651" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1226.2" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="576.48" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1004.7" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="871.805" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="443.584" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1285.26" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1530.77" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="842.273" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1353.57" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="443.584" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="423.298" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="487.883" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="275.635" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="468.52" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="275.635" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="821.987" cy="675.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1368.34" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1167.13" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="473.117" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="570.96" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="659.559" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="886.57" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="591.247" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1004.7" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="93.5178" cy="394.689" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1250.21" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="319.935" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="821.987" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1028.71" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="325.455" cy="93.5197" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1191.14" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="989.936" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="340.221" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="945.637" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.73" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="511.895" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1034.23" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="984.416" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1353.57" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1294.51" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="615.259" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="379" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="511.895" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="414.053" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="473.117" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="424.221" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="606.013" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="644.792" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1560.3" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1314.79" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1087.78" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="487.883" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="821.987" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="969.649" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="576.48" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="620.779" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="738.908" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1353.57" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="260.869" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1329.56" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1648.89" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="783.207" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="369.754" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="886.57" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="753.674" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="408.531" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1102.55" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1309.27" cy="438.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1324.04" cy="424.221" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="364.233" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="659.559" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1329.56" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="340.221" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1383.1" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="325.455" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="724.143" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1161.61" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1383.1" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1338.8" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="260.869" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="738.908" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1093.3" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1108.06" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7531" cy="424.221" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="999.182" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="600.492" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="753.674" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="93.5178" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="748.155" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7531" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1324.04" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1087.78" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1191.14" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1309.27" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1211.43" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1093.3" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="600.492" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1078.53" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="960.403" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="246.103" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="487.883" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="393.766" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="260.869" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="866.284" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="827.506" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="718.623" cy="719.546" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="310.688" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="408.531" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.73" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1176.38" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="275.635" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1359.09" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1501.23" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.9" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="295.922" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="295.922" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1575.07" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="424.221" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.66" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.66" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="458.351" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="842.273" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="606.013" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="748.155" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="561.715" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1122.83" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="975.169" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1309.27" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="753.674" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="502.651" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="137.819" cy="379.922" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="482.363" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="290.403" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1338.8" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1167.13" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="734.312" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1516" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="925.35" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1108.06" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1604.6" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="310.688" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1028.71" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1093.3" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1648.89" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1087.78" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="556.195" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1634.13" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="842.273" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="275.635" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="866.284" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="620.779" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="393.766" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="334.701" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="703.858" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="487.883" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="910.584" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="675.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="310.688" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1146.84" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1034.23" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="325.455" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="733.389" cy="675.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="704.779" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="414.053" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1087.78" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="615.259" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1132.08" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="674.324" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1545.53" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="600.492" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1353.57" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.9" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="443.584" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1270.49" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.66" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="295.922" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="541.428" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1049" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.66" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1176.38" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="526.661" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1383.1" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="960.403" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1486.47" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1205.91" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="674.324" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="620.779" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1589.83" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="738.908" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1314.79" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1019.47" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="690.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.66" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1368.34" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="591.247" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1226.2" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.9" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="733.389" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="665.077" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1294.51" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1501.23" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="482.363" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="620.779" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1028.71" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1589.83" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="556.195" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="733.389" cy="690.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="393.766" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="871.805" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1329.56" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="93.5178" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1530.77" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1087.78" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="526.661" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="325.455" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.9" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="290.403" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7531" cy="394.689" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="182.116" cy="424.221" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1102.55" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1294.51" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1589.83" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="319.935" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="546.948" cy="93.5197" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="246.103" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1235.44" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1102.55" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1314.79" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1093.3" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="842.273" cy="315.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="748.155" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="871.805" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1087.78" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="290.403" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="635.545" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="753.674" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="871.805" cy="315.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1545.53" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="748.155" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="482.363" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="502.651" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1087.78" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="511.895" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="497.129" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1034.23" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1309.27" cy="468.52" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1102.55" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1486.47" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="546.948" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="925.35" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="458.351" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="821.987" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="275.635" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="467.598" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="340.221" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1028.71" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="606.013" cy="93.5197" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="615.259" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="325.455" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="945.637" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.649" cy="438.987" r="4.92208" transform="rotate(-90 211.649 438.987)" fill="white" />
                    <circle className={styles.hidden} cx="349.466" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="246.103" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="394.689" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1049" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="275.635" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="925.35" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.73" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="842.273" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="290.403" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="910.584" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="857.039" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1589.83" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="487.883" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="541.428" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="379" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1501.23" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="724.143" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="975.169" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="734.312" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="384.52" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1300.03" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="310.688" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="182.116" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="690.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="453.753" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="290.403" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="989.936" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="585.727" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="709.375" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="748.155" cy="675.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="674.324" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="502.651" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1383.1" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="606.013" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="857.039" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="364.233" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="650.312" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="606.013" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="502.651" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="511.895" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="246.103" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="724.143" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1634.13" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="167.35" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="467.598" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1560.3" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="857.039" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="724.143" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="497.129" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="989.936" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="999.182" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7531" cy="379.922" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="275.635" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="738.908" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.66" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="984.416" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1087.78" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1220.67" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1338.8" cy="453.753" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="414.053" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1049" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="414.053" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1353.57" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1604.6" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="354.987" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1442.17" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1516" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="881.051" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="438.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="881.051" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.9" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="690.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1486.47" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="340.221" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="310.688" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="399.285" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1220.67" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.649" cy="468.519" r="4.92208" transform="rotate(-90 211.649 468.519)" fill="white" />
                    <circle className={styles.hidden} cx="1324.04" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1117.31" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="340.221" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="379.922" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1314.79" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1516" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="364.233" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="886.57" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="305.168" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="895.819" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="393.766" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="364.233" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1353.57" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="585.727" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1176.38" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="768.442" cy="63.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="984.416" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="783.207" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="738.908" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="487.883" cy="63.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="635.545" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="989.936" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1285.26" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7531" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1013.95" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="152.584" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="290.403" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="600.492" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="857.039" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1004.7" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="137.819" cy="394.689" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="319.935" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1146.84" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="319.935" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="674.324" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="719.546" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1146.84" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1191.14" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="857.039" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="753.674" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="414.053" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="674.324" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1353.57" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="561.715" cy="63.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1589.83" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.96" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1211.43" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="724.143" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1575.07" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1353.57" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="901.338" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1117.31" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1397.87" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="275.635" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="305.168" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1324.04" cy="438.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1486.47" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1226.2" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1294.51" cy="424.221" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1530.77" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="340.221" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="290.403" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="123.051" cy="394.689" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="182.116" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="473.117" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="409.454" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1604.6" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="719.546" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="675.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="665.077" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="340.221" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="384.52" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="502.651" cy="93.5197" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="502.651" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="379" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="497.129" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="319.935" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="768.442" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="857.039" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="576.48" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="881.051" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="738.908" cy="93.5197" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="886.57" cy="315.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="644.792" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1383.1" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1324.04" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="718.623" cy="675.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1167.13" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1028.71" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="738.908" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="620.779" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1122.83" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1545.53" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="969.649" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="703.858" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="310.688" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1329.56" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="325.455" cy="63.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1324.04" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="753.674" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="325.455" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="925.35" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1314.79" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1516" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="600.492" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="467.598" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="561.715" cy="93.5197" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1211.43" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1132.08" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1294.51" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="123.051" cy="379.922" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="630.026" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1359.09" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.9" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="458.351" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="428.819" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="724.143" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="369.754" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="438.065" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="467.598" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="797.975" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="703.858" cy="690.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1117.31" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="349.466" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="901.338" cy="315.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="570.96" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1161.61" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1176.38" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="709.375" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1324.04" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="468.52" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="340.221" cy="63.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1338.8" cy="438.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="591.247" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="644.792" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1516" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="340.221" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7531" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="674.324" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7531" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="591.247" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="659.559" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.649" cy="483.286" r="4.92208" transform="rotate(-90 211.649 483.286)" fill="white" />
                    <circle className={styles.hidden} cx="1034.23" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="502.651" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="527.584" r="4.92208" transform="rotate(-90 196.883 527.584)" fill="white" />
                    <circle className={styles.hidden} cx="473.117" cy="63.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1004.7" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1205.91" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="334.701" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1471.7" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1205.91" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="526.661" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1604.6" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="275.635" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="960.403" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="901.338" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1078.53" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="842.273" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1344.33" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="487.883" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1226.2" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1043.48" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1137.6" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="260.869" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="768.442" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="319.935" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.96" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1043.48" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1338.8" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="325.455" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="511.895" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="620.779" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1004.7" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1560.3" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1108.06" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="724.143" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1034.23" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="635.545" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1368.34" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="975.169" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1530.77" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1329.56" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1471.7" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="999.182" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1329.56" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1063.77" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="797.975" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="443.584" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7531" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1456.93" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1226.2" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="428.819" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="384.52" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1294.51" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="945.637" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1486.47" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1226.2" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="975.169" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1167.13" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="827.506" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1300.03" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1205.91" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="260.869" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1294.51" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="497.129" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="384.52" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1412.64" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1028.71" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="561.715" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="424.221" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1043.48" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="473.117" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1235.44" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="428.819" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="659.559" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="842.273" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1102.55" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1049" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="895.819" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="424.221" r="4.92208" transform="rotate(-90 196.883 424.221)" fill="white" />
                    <circle className={styles.hidden} cx="340.221" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1122.83" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="910.584" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.96" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="748.155" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="895.819" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1486.47" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="910.584" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1264.97" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="310.688" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="881.051" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="482.363" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1034.23" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1575.07" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="394.689" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1575.07" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1161.61" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1108.06" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1117.31" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1353.57" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1117.31" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="901.338" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="502.651" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="319.935" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.649" cy="424.221" r="4.92208" transform="rotate(-90 211.649 424.221)" fill="white" />
                    <circle className={styles.hidden} cx="1359.09" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="576.48" cy="63.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1004.7" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1471.7" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1486.47" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1167.13" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="497.129" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1294.51" cy="438.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="340.221" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="753.674" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1108.06" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="910.584" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="467.598" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="644.792" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="901.338" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="945.637" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1456.93" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.96" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="724.143" cy="93.5197" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1516" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1619.36" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1063.77" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="989.936" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="576.48" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="857.039" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1486.47" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1575.07" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="812.74" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="487.883" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="511.895" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7531" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="999.182" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1102.55" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="369.754" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1108.06" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="541.428" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1383.1" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="290.403" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="916.104" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="866.284" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1211.43" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="644.792" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="354.987" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="487.883" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1063.77" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1117.31" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="749.078" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1486.47" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="295.922" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1264.97" cy="468.52" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="842.273" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="482.363" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="989.936" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="615.259" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="453.753" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="369.754" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="738.908" cy="63.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1516" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.66" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.66" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1226.2" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="137.819" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1102.55" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="438.065" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="827.506" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1530.77" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="182.116" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1279.74" cy="468.52" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="753.674" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="305.168" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1078.53" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1132.08" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1397.87" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="384.52" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="733.389" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1344.33" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1211.43" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="989.936" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1167.13" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="762.922" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="340.221" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="650.312" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="482.363" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="487.883" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7531" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1211.43" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="408.531" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="473.117" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="665.077" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="511.895" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1368.34" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="954.883" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1456.93" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="526.661" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="453.753" r="4.92208" transform="rotate(-90 196.883 453.753)" fill="white" />
                    <circle className={styles.hidden} cx="630.026" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="738.908" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1471.7" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="295.922" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="748.155" cy="749.078" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="290.403" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="703.858" cy="675.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="783.207" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="724.143" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="591.247" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1368.34" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1205.91" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="812.74" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="502.651" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1545.53" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="866.284" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="871.805" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1078.53" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="325.455" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="260.869" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="925.35" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.96" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="295.922" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="556.195" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="940.116" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="910.584" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.649" cy="512.818" r="4.92208" transform="rotate(-90 211.649 512.818)" fill="white" />
                    <circle className={styles.hidden} cx="458.351" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="487.883" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="414.053" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="497.129" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="606.013" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="606.013" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="748.155" cy="690.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="886.57" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="620.779" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="635.545" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="733.389" cy="719.546" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1270.49" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1117.31" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="895.819" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="310.688" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1309.27" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="910.584" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1353.57" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1102.55" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="975.169" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="482.363" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="703.858" cy="734.312" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1545.53" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1013.95" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="827.506" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="827.506" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="792.455" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1314.79" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="295.922" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1108.06" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="975.169" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1300.03" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="340.221" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="502.651" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.9" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1368.34" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="630.026" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="984.416" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1456.93" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="93.5178" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1516" cy="498.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="591.247" cy="63.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1501.23" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="753.674" cy="93.5197" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="857.039" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="940.116" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1619.36" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="591.247" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="659.559" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="749.078" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.9" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1235.44" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="305.168" cy="571.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="93.5178" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="310.688" cy="93.5197" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1117.31" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1353.57" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1093.3" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1211.43" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1560.3" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1604.6" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1122.83" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="570.96" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="954.883" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1324.04" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="821.987" cy="438.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="674.324" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1619.36" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="895.819" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1058.25" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9875" cy="453.753" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1132.08" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="842.273" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="310.688" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="975.169" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="295.922" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="768.442" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="881.051" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1161.61" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1226.2" cy="137.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="182.116" cy="379.922" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="487.883" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1560.3" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="659.559" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1634.13" cy="512.819" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="871.805" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1589.83" cy="527.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="438.065" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="910.584" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="576.48" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1220.67" cy="616.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="606.013" cy="63.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1161.61" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="733.389" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="650.312" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="443.584" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="807.221" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1397.87" cy="630.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="709.375" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="733.389" cy="734.312" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2209" cy="601.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="777.688" cy="438.987" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="709.375" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="895.819" cy="542.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="260.869" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="305.168" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1063.77" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="881.051" cy="557.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1329.56" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="925.35" cy="645.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="821.987" cy="586.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1516" cy="483.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="585.727" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="925.35" cy="660.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="458.351" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="724.143" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="591.247" cy="108.286" r="4.92208" fill="white" />
                </svg>

                :
                <svg ref={svgRef} viewBox="0 0 2831 379" fill="none" xmlns="http://www.w3.org/2000/svg">

                    <circle className={styles.hidden} cx="447.909" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2367.52" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2278.92" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2337.99" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="182.117" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2204" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="137.818" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="595.571" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2544.71" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="566.039" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2648.08" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="935.195" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1269.89" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="433.143" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2633.31" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1540.61" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="551.273" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2426.59" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1880.23" cy="315.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2042.66" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1466.78" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1732.57" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.3" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2160.79" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="315.013" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="492.208" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1791.64" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="713.701" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2057.43" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2397.05" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1481.55" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1570.14" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2515.18" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2278.92" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="418.376" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="876.13" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="743.234" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="580.805" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2485.65" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2086.96" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1584.91" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="374.078" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1924.53" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2219.86" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="418.376" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="949.961" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="344.546" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1023.79" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2633.31" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2042.66" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1909.77" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2515.18" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2190.33" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2736.68" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="580.805" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1673.51" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1540.61" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="270.715" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="994.26" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="758" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="167.351" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="890.896" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2544.71" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2057.43" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="979.493" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="285.48" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1358.49" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2736.68" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="935.195" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="462.675" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1053.33" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2057.43" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2397.05" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1269.89" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1998.36" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="758" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="551.273" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2677.61" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2323.22" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="536.506" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="536.506" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1314.2" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1570.14" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.13" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2677.61" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2204" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2736.68" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1835.94" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="123.052" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2146.03" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="890.896" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2367.52" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2116.49" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="817.065" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.3" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2293.69" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="315.013" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="920.428" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2721.91" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1328.96" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1511.08" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2544.71" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="713.701" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2633.31" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1540.61" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1481.55" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="890.896" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1570.14" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2574.25" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.65" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="743.234" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.65" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9865" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="152.584" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="344.546" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1835.94" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9865" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="285.48" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2219.86" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="876.13" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2441.35" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2825.27" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="300.247" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2205.09" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2677.61" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2515.18" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.3" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2692.38" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="890.896" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2086.96" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1895" cy="329.779" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="551.273" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1466.78" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="935.195" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.65" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2485.65" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2337.99" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="698.935" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2190.33" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1673.51" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="123.052" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2751.44" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1009.03" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.13" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="315.013" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2219.86" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1314.2" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2072.2" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1703.04" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1821.17" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2204" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1776.87" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2072.2" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2529.95" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2751.44" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="802.299" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="329.779" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.13" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="447.909" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1762.1" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1284.66" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="462.675" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="167.351" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="108.285" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1643.97" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="595.571" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2648.08" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2116.49" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2264.16" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="595.571" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2205.09" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="758" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1747.34" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1673.51" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="743.234" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="152.584" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1540.61" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="285.48" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="743.234" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1269.89" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="684.169" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1210.83" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="536.506" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="447.909" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1422.48" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1570.14" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="876.13" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="300.247" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2485.65" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="758" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="876.13" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="595.571" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1821.17" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1688.27" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1850.7" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1038.56" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1422.48" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2337.99" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="344.546" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2529.95" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1998.36" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="123.052" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2515.18" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="300.247" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2721.91" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1791.64" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2293.69" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1909.77" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="285.48" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="654.636" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2662.84" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="905.662" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2204" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="831.831" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="477.442" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2736.68" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1776.87" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.65" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2411.82" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.13" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="994.26" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="167.351" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2470.88" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1038.56" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="462.675" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2042.66" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1481.55" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1895" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="447.909" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1210.83" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2323.22" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2751.44" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="344.546" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1673.51" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="315.013" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2057.43" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.3" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2086.96" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2692.38" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1688.27" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1835.94" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2057.43" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="270.715" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2323.22" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1806.4" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="152.584" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="270.715" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1762.1" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7536" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.13" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2692.38" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="462.675" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="536.506" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1835.94" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="93.5197" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="566.039" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2116.49" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2470.88" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="477.442" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="935.195" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1555.38" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="817.065" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2721.91" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2101.73" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2057.43" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1496.31" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1703.04" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1437.25" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2086.96" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2116.49" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="329.779" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1358.49" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="964.727" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="93.5197" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1009.03" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2810.51" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="772.766" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2707.14" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2485.65" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="817.065" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1328.96" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.13" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2810.51" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="595.571" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1437.25" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2131.26" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2662.84" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2308.45" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2559.48" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="374.077" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="462.675" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2116.49" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2780.97" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="580.805" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="566.039" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="949.961" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="137.818" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.65" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1422.48" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="477.442" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="610.338" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1732.57" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1895" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2662.84" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1388.03" cy="93.5193" r="4.92208" transform="rotate(-90 1388.03 93.5193)" fill="white" />
                    <circle className={styles.hidden} cx="2529.95" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2662.84" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2559.48" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.3" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1284.66" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="684.169" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1776.87" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2692.38" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1850.7" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="329.779" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2677.61" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="566.039" cy="315.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2175.56" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="964.727" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="462.675" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1732.57" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1584.91" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="300.247" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="669.403" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="300.247" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2146.03" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="433.143" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1328.96" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="167.351" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1210.83" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2766.21" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1895" cy="344.546" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2441.35" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="344.546" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1388.03" cy="78.7532" r="4.92208" transform="rotate(-90 1388.03 78.7532)" fill="white" />
                    <circle className={styles.hidden} cx="2456.12" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2618.55" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2515.18" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1835.94" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2086.96" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1496.31" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1038.56" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="684.169" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2293.69" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="890.896" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1452.01" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1747.34" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="477.442" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.13" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="300.247" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="772.766" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="772.766" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2529.95" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1924.53" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2175.56" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="713.701" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2751.44" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2766.21" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2515.18" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="890.896" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="787.532" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1998.36" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1791.64" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="374.077" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="876.13" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2086.96" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1358.49" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1791.64" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9865" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="255.948" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="462.675" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.13" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1614.44" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2766.21" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="521.74" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2736.68" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2500.42" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1850.7" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2470.88" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1658.74" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="669.403" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2367.52" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1481.55" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="610.338" cy="315.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="698.935" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2648.08" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2559.48" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2042.66" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="920.428" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1284.66" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2529.95" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2707.14" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1673.51" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1791.64" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2219.86" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1422.48" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1210.83" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1314.2" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="566.039" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="477.442" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2559.48" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="595.571" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1924.53" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2397.05" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.65" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="905.662" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1540.61" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="462.675" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="152.584" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="566.039" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1924.53" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="285.48" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2397.05" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1570.14" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2072.2" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1673.51" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1880.23" cy="329.779" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="359.311" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2352.75" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1452.01" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1658.74" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="270.715" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="831.831" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2146.03" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9865" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2131.26" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.06" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1688.27" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1762.1" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="964.727" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="388.844" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="580.805" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="329.779" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="551.273" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="492.208" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1762.1" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2500.42" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2042.66" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1466.78" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1452.01" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.06" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="758" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2515.18" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1643.97" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2721.91" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1909.77" cy="344.546" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="536.506" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1555.38" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="418.376" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="359.311" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="728.466" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2810.51" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2382.29" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2205.09" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="905.662" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="359.311" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="728.466" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2692.38" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2160.79" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1924.53" cy="359.311" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="433.143" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2721.91" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2101.73" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="669.403" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2352.75" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="831.831" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2736.68" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1328.96" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1658.74" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1269.89" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1358.49" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1732.57" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2500.42" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2795.74" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2146.03" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2293.69" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2160.79" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2662.84" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="374.077" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="447.909" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="270.715" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2234.62" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1835.94" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1388.03" cy="49.2207" r="4.92208" transform="rotate(-90 1388.03 49.2207)" fill="white" />
                    <circle className={styles.hidden} cx="1511.08" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2204" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.06" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2190.33" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2293.69" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="551.273" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1466.78" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2367.52" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="713.701" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="684.169" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="905.662" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2308.45" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1658.74" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2086.96" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1023.79" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2707.14" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="905.662" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1053.33" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="477.442" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1210.83" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="315.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="315.013" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="93.5197" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="743.234" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2131.26" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1584.91" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2264.16" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="595.571" cy="315.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2160.79" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2146.03" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="566.039" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="890.896" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="137.818" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2293.69" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2411.82" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2515.18" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1314.2" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1643.97" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1525.84" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="802.299" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1909.77" cy="374.078" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="935.195" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1388.03" cy="63.9871" r="4.92208" transform="rotate(-90 1388.03 63.9871)" fill="white" />
                    <circle className={styles.hidden} cx="1909.77" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.06" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1496.31" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2131.26" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="625.104" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="713.701" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="935.195" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="270.715" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2589.01" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2707.14" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="551.273" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2352.75" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1643.97" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2589.01" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7536" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2500.42" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2751.44" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="905.662" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2500.42" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2810.51" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2072.2" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2411.82" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2707.14" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1584.91" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1658.74" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2072.2" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1732.57" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="920.428" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="462.675" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="285.48" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1452.01" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2648.08" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="669.403" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.65" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="418.376" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1880.23" cy="344.546" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9865" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="300.247" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="713.701" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="654.636" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1584.91" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1009.03" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1747.34" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="876.13" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="905.662" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2101.73" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2205.09" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="344.546" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1023.79" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1422.48" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2367.52" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2751.44" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2146.03" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1570.14" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1540.61" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="123.052" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="506.974" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="802.299" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1924.53" cy="315.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="994.26" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="506.974" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="477.442" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2382.29" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="684.169" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="418.376" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="492.208" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7536" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1835.94" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1821.17" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="758" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="890.896" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1909.77" cy="315.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="758" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1452.01" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1821.17" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1555.38" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1762.1" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1924.53" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1717.8" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="802.299" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1511.08" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="728.466" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2426.59" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2308.45" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="329.779" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1481.55" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="698.935" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2692.38" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1658.74" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2500.42" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2795.74" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2131.26" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1762.1" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="374.077" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="684.169" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="831.831" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="876.13" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1791.64" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="890.896" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="359.312" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2204" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1835.94" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2751.44" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="802.299" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1762.1" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1210.83" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1643.97" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1673.51" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="418.376" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="949.961" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2795.74" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1496.31" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="492.208" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="359.312" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="167.351" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1373.26" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="920.428" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="300.247" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2308.45" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="817.065" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2780.97" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="728.466" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="418.376" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1314.2" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2190.33" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1850.7" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2766.21" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2707.14" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="698.935" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="802.299" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="949.961" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2204" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2337.99" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1358.49" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2780.97" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1717.8" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2042.66" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.06" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2337.99" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2175.56" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="329.779" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2190.33" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1343.73" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="920.428" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="831.831" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2677.61" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="949.961" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="536.506" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="315.013" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1924.53" cy="344.546" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2293.69" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1703.04" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="580.805" cy="315.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="994.26" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1909.77" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1688.27" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2352.75" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1717.8" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1388.03" cy="152.585" r="4.92208" transform="rotate(-90 1388.03 152.585)" fill="white" />
                    <circle className={styles.hidden} cx="1821.17" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1924.53" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="551.273" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2086.96" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="447.909" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="551.273" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2411.82" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="905.662" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2190.33" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1437.25" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="108.285" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7536" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="270.715" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="772.766" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2677.61" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2397.05" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="935.195" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="994.26" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.3" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1835.94" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2205.09" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2397.05" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="108.285" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="418.376" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="462.675" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="462.675" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1328.96" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="93.5197" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2707.14" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.06" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1732.57" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2146.03" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="743.234" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="654.636" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="551.273" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.65" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1555.38" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="654.636" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="817.065" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2264.16" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2101.73" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="566.039" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1299.43" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2278.92" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2736.68" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="433.143" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2204" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2101.73" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1210.83" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9865" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1776.87" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2780.97" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2500.42" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.13" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="787.532" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2308.45" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1555.38" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="610.338" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1373.26" cy="108.286" r="4.92208" transform="rotate(-90 1373.26 108.286)" fill="white" />
                    <circle className={styles.hidden} cx="2337.99" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2293.69" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1481.55" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1895" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="831.831" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="698.935" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="123.052" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2042.66" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2721.91" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1525.84" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="551.273" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="979.493" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2264.16" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2515.18" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="610.338" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2485.65" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1210.83" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1437.25" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2633.31" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1481.55" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="492.208" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="477.442" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="329.779" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2204" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1998.36" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="93.5197" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1643.97" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1998.36" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1850.7" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1614.44" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1703.04" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1924.53" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="817.065" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2677.61" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2131.26" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1835.94" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1540.61" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1555.38" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="359.312" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2278.92" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="580.805" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1821.17" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1373.26" cy="93.5193" r="4.92208" transform="rotate(-90 1373.26 93.5193)" fill="white" />
                    <circle className={styles.hidden} cx="2367.52" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1343.73" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1909.77" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1909.77" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2648.08" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="270.715" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="329.779" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="728.466" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1688.27" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.06" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2707.14" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="684.169" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2116.49" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="802.299" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="669.403" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="182.117" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2529.95" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.65" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1009.03" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="920.428" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2456.12" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="905.662" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2515.18" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2500.42" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2515.18" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2810.51" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1373.26" cy="63.9871" r="4.92208" transform="rotate(-90 1373.26 63.9871)" fill="white" />
                    <circle className={styles.hidden} cx="1452.01" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1358.49" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2057.43" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="964.727" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="447.909" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="329.779" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2559.48" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2470.88" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2470.88" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2574.25" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="610.338" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="817.065" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1422.48" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="300.247" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1068.09" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="374.077" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1895" cy="374.078" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.65" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.3" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="580.805" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="595.571" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2160.79" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1835.94" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1373.26" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1343.73" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="270.715" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2190.33" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2766.21" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1998.36" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1688.27" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2692.38" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1599.68" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="580.805" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1373.26" cy="152.585" r="4.92208" transform="rotate(-90 1373.26 152.585)" fill="white" />
                    <circle className={styles.hidden} cx="1068.09" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="506.974" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1009.03" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="300.247" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="285.48" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1540.61" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2648.08" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="315.013" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9865" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2057.43" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2411.82" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="949.961" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1717.8" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="285.48" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="137.818" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="433.143" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1747.34" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="669.403" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2515.18" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2559.48" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2470.88" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="182.117" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1643.97" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.13" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1481.55" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2072.2" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1210.83" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.06" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2175.56" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2736.68" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="418.376" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1038.56" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.65" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2485.65" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2662.84" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1038.56" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1895" cy="315.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="994.26" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1673.51" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1895" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2278.92" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2323.22" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="137.818" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1358.49" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1555.38" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="447.909" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1373.26" cy="137.818" r="4.92208" transform="rotate(-90 1373.26 137.818)" fill="white" />
                    <circle className={styles.hidden} cx="2529.95" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2677.61" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2293.69" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1909.77" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="182.117" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="329.779" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2293.69" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7536" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2308.45" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="433.143" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1314.2" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1452.01" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1776.87" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="182.117" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2042.66" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2751.44" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2352.75" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="315.013" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="566.039" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="255.948" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="536.506" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="433.143" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2648.08" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2500.42" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1850.7" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="551.273" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1525.84" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="595.571" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2456.12" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.3" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2205.09" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2736.68" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2574.25" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1299.43" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="905.662" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1924.53" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2515.18" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1924.53" cy="329.779" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2529.95" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="654.636" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1481.55" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2057.43" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="802.299" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1437.25" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="551.273" cy="315.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="566.039" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2204" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2766.21" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="698.935" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="920.428" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2721.91" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="625.104" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="536.506" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2500.42" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="979.493" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1776.87" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2116.49" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2766.21" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="182.117" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="964.727" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.13" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1388.03" cy="137.818" r="4.92208" transform="rotate(-90 1388.03 137.818)" fill="white" />
                    <circle className={styles.hidden} cx="2175.56" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2707.14" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1284.66" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.65" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1998.36" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="935.195" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="241.181" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="344.546" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1466.78" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="595.571" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="684.169" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2190.33" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.3" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2204" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="654.636" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="713.701" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2352.75" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1373.26" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2751.44" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2086.96" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1909.77" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="285.48" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="772.766" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2219.86" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="890.896" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2500.42" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="787.532" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="462.675" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1496.31" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2780.97" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="433.143" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="787.532" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="152.584" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1038.56" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="433.143" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="669.403" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1422.48" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="433.143" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1880.23" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="698.935" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="787.532" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1806.4" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.06" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="167.351" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2825.27" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="255.948" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2692.38" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="344.546" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="521.74" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1924.53" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="137.818" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2382.29" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1806.4" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2736.68" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1599.68" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="108.285" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="994.26" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1658.74" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="713.701" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2382.29" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="315.013" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2219.86" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1343.73" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1466.78" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="580.805" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="300.247" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="787.532" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="787.532" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="344.546" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="920.428" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1909.77" cy="359.311" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="580.805" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1835.94" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="669.403" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7536" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2072.2" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="536.506" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9865" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2204" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2367.52" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.06" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="964.727" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1525.84" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="433.143" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2677.61" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="787.532" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1496.31" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.06" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="595.571" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1373.26" cy="49.2207" r="4.92208" transform="rotate(-90 1373.26 49.2207)" fill="white" />
                    <circle className={styles.hidden} cx="817.065" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1658.74" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="831.831" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.06" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2205.09" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1452.01" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1210.83" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="477.442" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2072.2" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1998.36" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="698.935" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2175.56" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1210.83" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.65" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="728.466" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1614.44" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2662.84" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2662.84" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2736.68" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1584.91" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="905.662" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2515.18" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="344.546" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="315.013" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="447.909" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1437.25" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2766.21" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1466.78" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2086.96" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2131.26" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1747.34" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1299.43" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1481.55" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="506.974" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1511.08" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2278.92" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1437.25" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="418.376" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="743.234" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="713.701" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2500.42" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2382.29" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1570.14" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="315.013" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2500.42" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1806.4" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="315.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="418.376" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2397.05" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="182.117" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1673.51" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2205.09" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="433.143" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2795.74" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="300.247" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1023.79" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1703.04" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2278.92" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1210.83" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="536.506" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.06" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="123.052" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2086.96" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="905.662" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="152.584" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="315.013" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2264.16" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1496.31" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="315.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2205.09" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1806.4" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="713.701" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="315.013" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2559.48" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="802.299" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="359.312" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2500.42" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="93.5197" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2515.18" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="359.312" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2574.25" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2795.74" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="654.636" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="684.169" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1880.23" cy="359.311" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="300.247" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1023.79" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2662.84" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2766.21" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1555.38" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="388.844" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1850.7" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="359.312" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.06" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="595.571" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1009.03" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="93.5197" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1388.03" cy="108.286" r="4.92208" transform="rotate(-90 1388.03 108.286)" fill="white" />
                    <circle className={styles.hidden} cx="1181.3" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1284.66" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1210.83" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1998.36" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2264.16" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.06" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="329.779" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1688.27" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2529.95" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1009.03" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1717.8" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="566.039" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="108.285" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="374.078" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="300.247" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2795.74" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1210.83" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1496.31" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1496.31" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="580.805" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="905.662" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="492.208" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1998.36" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2780.97" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="447.909" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="949.961" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1673.51" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="920.428" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2278.92" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="698.935" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="610.338" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="123.052" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="506.974" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1821.17" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1422.48" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2397.05" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="359.312" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="315.013" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="625.104" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2515.18" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2751.44" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="108.285" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2293.69" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="418.376" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="935.195" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2293.69" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="772.766" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="447.909" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2751.44" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="462.675" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1023.79" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1747.34" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="949.961" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1299.43" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1924.53" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2766.21" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1658.74" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.65" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1299.43" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1850.7" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1269.89" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2780.97" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1437.25" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2574.25" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2278.92" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1909.77" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="521.74" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1466.78" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2515.18" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="462.675" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1343.73" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1570.14" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2146.03" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="477.442" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1269.89" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2500.42" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="329.779" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="713.701" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2264.16" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2293.69" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="684.169" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2485.65" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1658.74" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="698.935" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1599.68" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="920.428" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1688.27" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1437.25" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2470.88" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2544.71" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1599.68" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="979.493" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1023.79" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="315.013" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2544.71" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1373.26" cy="123.052" r="4.92208" transform="rotate(-90 1373.26 123.052)" fill="white" />
                    <circle className={styles.hidden} cx="1511.08" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.65" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2589.01" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2352.75" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2780.97" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1452.01" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.13" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="536.506" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2470.88" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="418.376" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="728.466" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1688.27" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="433.143" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.13" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="802.299" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1717.8" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="329.779" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="669.403" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2780.97" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2072.2" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.65" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="462.675" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1437.25" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1038.56" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="270.715" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="492.208" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2204" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.06" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="182.117" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1998.36" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2308.45" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="300.247" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1776.87" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="447.909" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2707.14" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="846.597" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="935.195" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2780.97" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="728.466" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1511.08" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="285.48" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2810.51" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1570.14" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="433.143" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1924.53" cy="374.078" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2042.66" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2544.71" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2234.62" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2323.22" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1452.01" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1909.77" cy="329.779" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="566.039" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2707.14" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1009.03" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="817.065" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="876.13" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1481.55" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2101.73" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2766.21" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="920.428" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2323.22" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1210.83" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1053.33" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="669.403" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2204" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="935.195" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2308.45" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1481.55" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="152.584" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1210.83" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1584.91" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1703.04" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2485.65" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2101.73" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="920.428" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="447.909" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1269.89" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2470.88" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1068.09" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2529.95" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.13" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="182.117" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="580.805" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1747.34" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2160.79" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="93.5197" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="300.247" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1388.03" cy="123.052" r="4.92208" transform="rotate(-90 1388.03 123.052)" fill="white" />
                    <circle className={styles.hidden} cx="1038.56" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1643.97" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2175.56" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="846.597" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1599.68" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="654.636" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.13" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1466.78" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2795.74" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2485.65" cy="93.5195" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="359.312" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="344.546" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="994.26" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="890.896" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2382.29" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2057.43" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1688.27" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="949.961" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="477.442" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="433.143" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="123.052" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1880.23" cy="300.247" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1328.96" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2323.22" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="920.428" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2662.84" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1584.91" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="167.351" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1703.04" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1880.23" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="108.285" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1269.89" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2204" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="329.779" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1053.33" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2559.48" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1299.43" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2544.71" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1998.36" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1452.01" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2057.43" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2101.73" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2160.79" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2278.92" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2131.26" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1658.74" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="182.117" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="329.779" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1821.17" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2086.96" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2204" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1452.01" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="285.48" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="4.92208" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="698.935" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2352.75" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1009.03" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2382.29" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2485.65" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1998.36" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="536.506" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1240.36" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2544.71" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2337.99" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2618.55" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="447.909" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1466.78" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2721.91" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1895" cy="359.311" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1643.97" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1358.49" cy="19.6887" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2411.82" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1703.04" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="964.727" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1053.33" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1358.49" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2308.45" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2264.16" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="329.779" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2751.44" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2190.33" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="344.546" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1210.83" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2337.99" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1717.8" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="108.285" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1673.51" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1806.4" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1924.53" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="241.181" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="566.039" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1373.26" cy="78.7532" r="4.92208" transform="rotate(-90 1373.26 78.7532)" fill="white" />
                    <circle className={styles.hidden} cx="2367.52" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2795.74" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="315.013" cy="49.2209" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1555.38" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2500.42" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2766.21" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="344.546" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="772.766" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="698.935" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="19.6877" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="315.013" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1452.01" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2175.56" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="787.532" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2308.45" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="698.935" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="772.766" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="137.818" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2795.74" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2219.86" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1998.36" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2190.33" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2072.2" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1821.17" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1511.08" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2204" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2072.2" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="758" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="447.909" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2204" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1466.78" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="580.805" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2160.79" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="315.013" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1328.96" cy="4.92208" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1196.06" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1466.78" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2101.73" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1023.79" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1850.7" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2278.92" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="447.909" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="935.195" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2529.95" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2116.49" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2544.71" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.3" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="315.013" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="684.169" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2515.18" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="949.961" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1343.73" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="433.143" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="63.9865" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2470.88" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="152.584" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="49.2204" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2633.31" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="137.818" cy="34.4543" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="890.896" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="34.4553" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2529.95" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="654.636" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="669.403" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1210.83" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2086.96" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1496.31" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1225.6" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2264.16" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2485.65" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1688.27" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1939.3" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2544.71" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1210.83" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2500.42" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="167.351" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="935.195" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2072.2" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="684.169" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1181.3" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2278.92" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="196.883" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1255.13" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="211.649" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2736.68" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1466.78" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2175.56" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2677.61" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1806.4" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1343.73" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="418.376" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1481.55" cy="226.415" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1998.36" cy="63.9873" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2692.38" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1998.36" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="743.234" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1850.7" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2721.91" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1791.64" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2101.73" cy="182.117" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2692.38" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1023.79" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1437.25" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2529.95" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1968.83" cy="167.351" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7536" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2278.92" cy="196.883" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="551.273" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="625.104" cy="285.481" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1732.57" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="78.7536" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1437.25" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="388.844" cy="270.714" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2382.29" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2264.16" cy="152.585" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="211.65" cy="123.052" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2795.74" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1983.6" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="1954.06" cy="78.7531" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2323.22" cy="241.182" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="462.675" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2648.08" cy="255.948" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="300.247" cy="137.818" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2101.73" cy="108.286" r="4.92208" fill="white" />
                    <circle className={styles.hidden} cx="2780.97" cy="123.052" r="4.92208" fill="white" />

                </svg>

            }


        </div>
    );

}

export default Other;
