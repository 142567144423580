import React, { useState } from 'react';
import { useEffect, useRef } from 'react';
import styles from './projects.module.css';

function Spotlight() {

    const svgRef = useRef(null);
    const [isMobile, setMobile] = useState(false);

    useEffect(() => {
        const svg = svgRef.current;
        const paths = svg.querySelectorAll(`.${styles.hidden.split(' ')[0]}`);

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {

                    setMobile(window.innerWidth <= 800);
                    let delay = 0;
                    paths.forEach((path) => {
                        setTimeout(() => {
                            path.classList.remove(styles.hidden);
                            path.style.opacity = '1';
                        }, delay);

                        delay += 0.5;
                    });

                    const observerInterval = setInterval(() => {

                        const noww = performance.now() / 1000
                        const xparam = 1.9 * Math.sin(noww)
                        const yparam = 2.1 * Math.cos(noww)
                        paths.forEach((path) => {

                            path.setAttribute('r', (xparam * Math.cos(parseFloat(path.getAttribute('cx')) / 400) + yparam * Math.sin(parseFloat(path.getAttribute('cy')) / 150) + 5.5));

                        });
                    }, 100);

                    return () => {
                        clearInterval(observerInterval); // Clear the interval when the observer is disconnected
                    };

                }
                else {

                    setMobile(window.innerWidth <= 800);
                    paths.forEach((path) => {
                        path.classList.add(styles.hidden);
                        path.style.opacity = '0';
                    });

                }
            });
        });

        observer.observe(svg);

        return () => {
            observer.unobserve(svg);
        };
    }, [svgRef]);

    return (
        <div className={styles.spotlightTitle}>

            {isMobile ?
                <svg viewBox="0 0 1690 826" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle classname={styles.hidden} cx="660.304" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1048.72" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="500.084" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="941.905" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1117.18" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1048.72" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="718.567" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1223.51" cy="776.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="316.076" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1626.49" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1077.85" cy="456.421" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="383.559" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.34" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="310.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="165.076" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="316.076" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="500.084" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="418.035" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1452.19" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="339.862" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="286.945" cy="19.4206" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1310.9" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1383.73" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1471.12" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1048.72" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1437.62" cy="63.1174" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="578.256" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1354.6" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="485.517" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="941.905" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1000.66" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="587.477" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="325.298" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1611.92" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1310.9" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="165.076" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.47" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1452.19" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="941.905" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1350.23" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1117.18" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="33.986" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="165.076" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="558.345" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1670.18" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1058.92" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="48.5518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1514.82" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="165.076" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1582.79" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="135.945" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="184.986" cy="63.1174" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="747.698" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="388.904" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="92.2478" cy="456.421" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="835.091" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1500.25" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1223.51" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="883.643" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1252.64" cy="776.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1165.24" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1466.75" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="840.435" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1543.95" cy="747.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1194.37" cy="820.559" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="456.386" cy="602.076" r="4.85518" transform="rotate(-90 456.386 602.076)" fill="white" />
                    <circle classname={styles.hidden} cx="267.035" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="359.773" cy="150.511" r="4.85518" transform="rotate(-90 359.773 150.511)" fill="white" />
                    <circle classname={styles.hidden} cx="170.421" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="310.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1611.92" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1423.06" cy="48.5518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="776.829" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="694.78" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1525.02" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1452.19" cy="92.2484" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="680.215" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1048.72" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="519.994" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="179.641" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1296.33" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1160.88" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1252.64" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="310.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1281.77" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="92.2478" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1398.29" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1175.44" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="252.469" cy="776.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="631.174" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1150.68" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="500.084" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="514.65" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1466.75" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1325.46" cy="747.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="63.1169" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1262.84" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="257.814" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1277.4" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1146.31" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1252.64" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="500.084" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="543.781" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1048.72" cy="456.421" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1529.38" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1437.62" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="184.986" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1131.75" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1423.06" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1369.16" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1500.25" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1252.64" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="805.961" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.47" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1019.59" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1423.06" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="281.6" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="587.477" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1514.82" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="733.133" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1485.69" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="19.4206" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1063.28" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="776.829" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1495.89" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1034.15" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1131.75" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="855.002" cy="310.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1335.66" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="77.6833" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="339.862" cy="776.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="179.641" cy="456.421" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="403.469" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="738.477" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1597.36" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1597.36" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1296.33" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1500.25" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1611.92" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1568.22" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="805.961" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="636.518" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="941.905" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1223.51" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1146.31" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1611.92" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1102.62" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1262.84" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="228.683" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="33.986" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="476.297" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1408.49" cy="92.2484" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="286.945" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1296.33" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="456.386" cy="616.642" r="4.85518" transform="rotate(-90 456.386 616.642)" fill="white" />
                    <circle classname={styles.hidden} cx="1267.2" cy="762.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1131.75" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1015.22" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="135.945" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1582.79" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1121.55" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="665.649" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.34" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="840.435" cy="296.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="194.207" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="155.855" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1383.73" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1481.32" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1165.24" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="811.304" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1238.07" cy="805.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="563.691" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1553.66" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1044.35" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="398.125" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="912.774" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="529.215" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="135.945" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1208.94" cy="776.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="150.51" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="330.641" cy="48.5518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1597.36" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1481.32" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.961" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1131.75" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1641.05" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1165.24" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="694.78" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1510.45" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1019.59" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="403.469" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="645.739" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="549.125" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1131.75" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1058.92" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="820.559" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.47" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1281.77" cy="791.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1034.15" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1121.55" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="432.601" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="461.732" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1306.53" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1165.24" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="738.477" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1364.8" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="150.51" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="121.379" cy="441.855" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1262.84" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="194.207" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="48.5518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1495.89" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="558.345" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1219.14" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="782.174" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="208.772" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1626.49" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1310.9" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="883.643" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="505.428" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1452.19" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.34" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1000.66" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1146.31" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="398.125" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1194.37" cy="791.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="514.65" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1063.28" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="325.298" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1219.14" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="121.379" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="476.297" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1423.06" cy="63.1174" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1641.05" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1034.15" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1160.88" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1306.53" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1179.81" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="674.87" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1383.73" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="150.51" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="281.6" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="63.1169" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1611.92" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1529.38" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1485.69" cy="747.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1150.68" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1471.12" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="48.5515" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1267.2" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="403.469" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="651.084" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="776.829" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="339.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1238.07" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="77.6833" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="286.945" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="330.641" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="689.435" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="92.2484" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1248.27" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1165.24" cy="776.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.47" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1044.35" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1398.29" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="753.043" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1335.66" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="223.339" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1077.85" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="912.774" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1321.1" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1611.92" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1000.66" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="63.1169" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1219.14" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="184.986" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="884.133" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="680.215" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1073.49" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1437.62" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="179.641" cy="441.855" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1383.73" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="441.822" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="985.601" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="257.814" cy="19.4206" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="354.428" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1525.02" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="660.304" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="403.469" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1398.29" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="19.4206" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1369.16" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="228.683" cy="48.5518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="549.125" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1063.28" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1495.89" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.209" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="208.772" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="456.386" cy="660.338" r="4.85518" transform="rotate(-90 456.386 660.338)" fill="white" />
                    <circle classname={styles.hidden} cx="1626.49" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1281.77" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1131.75" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="796.74" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="941.905" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1117.18" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="543.781" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1233.71" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1034.15" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="267.035" cy="805.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="543.781" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="316.076" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1412.86" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1364.8" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="884.133" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="388.904" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="534.559" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.209" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1495.89" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="150.51" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1641.05" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1088.05" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="325.298" cy="820.559" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1219.14" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="388.904" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1500.25" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="791.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="529.215" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="621.953" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1423.06" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="252.469" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1335.66" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="330.641" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="621.953" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1252.64" cy="791.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="354.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="121.379" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="616.608" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="461.732" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.34" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1597.36" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1165.24" cy="805.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="738.477" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="165.076" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1262.84" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="316.076" cy="4.85518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1267.2" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="782.174" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="354.428" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="223.339" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="33.986" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="563.691" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1514.82" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1597.36" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="971.036" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1262.84" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="767.608" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="77.6833" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="252.469" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="398.125" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="884.133" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="791.394" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="912.774" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="840.435" cy="339.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="106.814" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="325.298" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="19.4206" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="19.4206" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="549.125" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="694.78" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="665.649" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="383.559" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1208.94" cy="791.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="941.905" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1423.06" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1514.82" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1160.88" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1543.95" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1190.01" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1092.42" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1019.59" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1208.94" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1029.79" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="33.986" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="791.394" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1034.15" cy="441.855" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="636.518" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="106.814" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1582.79" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1514.82" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="529.215" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="476.297" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1437.62" cy="48.5518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1383.73" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="150.51" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1485.69" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1034.15" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="912.774" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1427.42" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1466.75" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="345.208" cy="106.814" r="4.85518" transform="rotate(-90 345.208 106.814)" fill="white" />
                    <circle classname={styles.hidden} cx="1321.1" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1306.53" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1383.73" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="63.1169" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1044.35" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="427.256" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="354.428" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.34" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.961" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="267.035" cy="791.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1310.9" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1136.11" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="884.133" cy="310.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="621.953" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1019.59" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1223.51" cy="791.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="791.394" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1354.6" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="33.986" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1568.22" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1452.19" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="709.346" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="48.5515" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="272.38" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="912.774" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1208.94" cy="805.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1296.33" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="971.036" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="820.525" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1427.42" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1466.75" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="645.739" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.961" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1238.07" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1179.81" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1452.19" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1044.35" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="884.133" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="855.002" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="418.035" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="689.435" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="747.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="184.986" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="971.036" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1121.55" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="747.698" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1058.92" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.34" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="884.133" cy="368.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="456.386" cy="587.511" r="4.85518" transform="rotate(-90 456.386 587.511)" fill="white" />
                    <circle classname={styles.hidden} cx="1597.36" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="92.2478" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1495.89" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="165.076" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="767.608" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="267.035" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="835.091" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="92.2478" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1150.68" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="855.002" cy="296.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="723.912" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="4.85518" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1597.36" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="811.304" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="368.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="651.084" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1233.71" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="709.346" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1175.44" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.961" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="776.829" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="194.207" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="694.78" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1408.49" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="811.304" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1456.55" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="432.601" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="864.222" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="281.6" cy="820.559" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="92.2478" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="325.298" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1626.49" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="762.263" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="447.166" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1611.92" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="228.683" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1335.66" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1500.25" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="941.905" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1437.62" cy="77.6829" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="572.912" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1350.23" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.47" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1423.06" cy="77.6829" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="941.905" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1252.64" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="447.166" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="767.608" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1233.71" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="184.986" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="805.961" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="616.608" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="782.174" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1179.81" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1034.15" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1058.92" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1034.15" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1383.73" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1088.05" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="651.084" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="228.683" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="971.036" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="339.862" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="738.477" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="621.953" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="194.207" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1398.29" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="194.207" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="549.125" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="208.772" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1088.05" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="359.773" cy="63.1174" r="4.85518" transform="rotate(-90 359.773 63.1174)" fill="white" />
                    <circle classname={styles.hidden} cx="194.207" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1029.79" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="680.215" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="796.74" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="820.525" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="855.002" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1048.72" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="840.435" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1063.28" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="165.076" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="660.304" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1146.31" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1626.49" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="762.263" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="805.961" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="170.421" cy="77.6829" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1136.11" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1423.06" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1597.36" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="718.567" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="63.1169" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="631.174" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1369.16" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="267.035" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="155.855" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="184.986" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1073.49" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1514.82" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="941.905" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="753.043" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="912.774" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1044.35" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1514.82" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="621.953" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="811.304" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="252.469" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="636.518" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1456.55" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1611.92" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="267.035" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1369.16" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1495.89" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="660.304" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="135.945" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1092.42" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1306.53" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="855.002" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="762.263" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1092.42" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.961" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1015.22" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1412.86" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="776.829" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="884.133" cy="354.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="398.125" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="339.862" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1398.29" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="165.076" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="418.035" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="121.379" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1000.66" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1296.33" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1131.75" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="514.65" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="941.905" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="418.035" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="243.248" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="514.65" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="762.263" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="359.773" cy="92.2485" r="4.85518" transform="rotate(-90 359.773 92.2485)" fill="white" />
                    <circle classname={styles.hidden} cx="252.469" cy="747.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="636.518" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1543.95" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="621.953" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="296.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="19.4206" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1262.84" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1495.89" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="689.435" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="855.002" cy="339.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="281.6" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="490.863" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="441.822" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="723.912" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1208.94" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="447.166" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="602.043" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="514.65" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="345.208" cy="48.5519" r="4.85518" transform="rotate(-90 345.208 48.5519)" fill="white" />
                    <circle classname={styles.hidden} cx="150.51" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="747.698" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1179.81" cy="791.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="869.567" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1165.24" cy="791.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1466.75" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1058.92" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="602.043" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="170.421" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="747.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="77.6829" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="456.386" cy="674.904" r="4.85518" transform="rotate(-90 456.386 674.904)" fill="white" />
                    <circle classname={styles.hidden} cx="1529.38" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.209" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1063.28" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="92.2478" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="869.567" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="427.256" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1412.86" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="796.74" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1539.58" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="184.986" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="869.567" cy="310.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1335.66" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1102.62" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1179.81" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="776.829" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="316.076" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="33.986" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1034.15" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1136.11" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1354.6" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="971.036" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1597.36" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1568.22" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1102.62" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="796.74" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="77.6829" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1160.88" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="281.6" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1611.92" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="461.732" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1383.73" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="228.683" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="747.698" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="616.608" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="762.263" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1015.22" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1529.38" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="733.133" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="296.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="805.961" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="651.084" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="791.394" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1073.49" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="354.428" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1539.58" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1044.35" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1019.59" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1136.11" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="170.421" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="607.386" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="621.953" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="252.469" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="941.905" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="208.772" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="427.256" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1539.09" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1044.35" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="325.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="252.469" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1383.73" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1321.1" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1310.9" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="782.174" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="194.207" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="820.525" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="587.477" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="150.51" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="330.641" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1019.59" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="403.469" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="776.829" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="345.208" cy="135.945" r="4.85518" transform="rotate(-90 345.208 135.945)" fill="white" />
                    <circle classname={styles.hidden} cx="461.732" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="243.248" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1194.37" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="776.829" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="150.51" cy="456.421" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1208.94" cy="762.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1412.86" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="63.1169" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="776.829" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1044.35" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="228.683" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="791.394" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1281.77" cy="776.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1019.59" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1063.28" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="106.814" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="427.256" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1529.38" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1383.73" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="447.166" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1525.02" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="835.091" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1015.22" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1369.16" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="461.732" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1073.49" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="796.74" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1277.4" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="286.945" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="796.74" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="869.567" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1335.66" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="941.905" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="651.084" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="441.822" cy="631.207" r="4.85518" transform="rotate(-90 441.822 631.207)" fill="white" />
                    <circle classname={styles.hidden} cx="184.986" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1252.64" cy="820.559" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1248.27" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1150.68" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="368.994" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="345.208" cy="121.38" r="4.85518" transform="rotate(-90 345.208 121.38)" fill="white" />
                    <circle classname={styles.hidden} cx="1568.22" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="602.043" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1296.33" cy="791.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1466.75" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1539.09" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="941.905" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="267.035" cy="762.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="476.297" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1136.11" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1136.11" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1238.07" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1383.73" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="791.394" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.961" cy="63.1174" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1481.32" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1194.37" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="194.207" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="208.772" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="412.69" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="208.772" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1219.14" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1160.88" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="170.421" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="432.601" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="791.394" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="19.4206" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="165.076" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="33.986" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.34" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1029.79" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1044.35" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1077.85" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="529.215" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1175.44" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1150.68" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="985.601" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1597.36" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="155.855" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="883.643" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="432.601" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1369.16" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="543.781" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="345.208" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="636.518" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="855.002" cy="368.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="791.394" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="447.166" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="505.428" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1190.01" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="476.297" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1626.49" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="184.986" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="228.683" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="179.641" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1568.22" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1194.37" cy="805.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="325.298" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1383.73" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.961" cy="48.5518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1641.05" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="359.773" cy="106.814" r="4.85518" transform="rotate(-90 359.773 106.814)" fill="white" />
                    <circle classname={styles.hidden} cx="1611.92" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="602.043" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="971.036" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1146.31" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1354.6" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.47" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="325.298" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1277.4" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1350.23" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1121.55" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="398.125" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="257.814" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1179.81" cy="776.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="534.559" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1223.51" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1611.92" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1354.6" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="490.863" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1398.29" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="252.469" cy="805.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1626.49" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="194.207" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1597.36" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1179.81" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="572.912" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1019.59" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1015.22" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="281.6" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1670.18" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="796.74" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1000.66" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1452.19" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1412.86" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="558.345" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1310.9" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1044.35" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1471.12" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="572.912" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="587.477" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="607.386" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1165.24" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1452.19" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="985.601" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="607.386" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1655.62" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1568.22" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="296.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1529.38" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1471.12" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1412.86" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1310.9" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1364.8" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1427.42" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="884.133" cy="296.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1369.16" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="791.394" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1291.97" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1048.72" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1553.66" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="572.912" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="651.084" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="776.829" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="665.649" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="791.394" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1514.82" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1427.42" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="281.6" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1136.11" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="820.525" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="345.208" cy="19.4206" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1485.69" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="607.386" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1248.27" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="135.945" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="549.125" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1208.94" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="165.076" cy="441.855" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="281.6" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1641.05" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1510.45" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="179.641" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1354.6" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1277.4" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="820.525" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1019.59" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1369.16" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="776.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.47" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1684.75" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1510.45" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1670.18" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1219.14" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1233.71" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="345.208" cy="92.2485" r="4.85518" transform="rotate(-90 345.208 92.2485)" fill="white" />
                    <circle classname={styles.hidden} cx="753.043" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="869.567" cy="339.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.34" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="48.5515" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="869.567" cy="354.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="257.814" cy="4.85518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="106.814" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1136.11" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="286.945" cy="4.85518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="301.51" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="616.608" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="427.256" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.961" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1510.45" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1485.69" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.34" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1063.28" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1626.49" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="753.043" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="811.304" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="19.4206" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="805.961" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1281.77" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1121.55" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1058.92" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1611.92" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="971.036" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1325.46" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="267.035" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="121.379" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1262.84" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1325.46" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1063.28" cy="441.855" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1306.53" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="762.263" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="884.133" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1452.19" cy="63.1174" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="432.601" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="325.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="155.855" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1073.49" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="563.691" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1296.33" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.961" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.961" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1267.2" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="135.945" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="48.5518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="529.215" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1325.46" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="782.174" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="208.772" cy="456.421" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1597.36" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="855.002" cy="354.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1233.71" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="427.256" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1684.75" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="864.222" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1267.2" cy="776.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="92.2478" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="441.822" cy="660.338" r="4.85518" transform="rotate(-90 441.822 660.338)" fill="white" />
                    <circle classname={styles.hidden} cx="325.298" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="782.174" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="461.732" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="514.65" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="135.945" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="441.822" cy="616.642" r="4.85518" transform="rotate(-90 441.822 616.642)" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="121.379" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="63.1169" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="441.822" cy="645.773" r="4.85518" transform="rotate(-90 441.822 645.773)" fill="white" />
                    <circle classname={styles.hidden} cx="1058.92" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="665.649" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="412.69" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="252.469" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1233.71" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="805.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1369.16" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1393.93" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1471.12" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="820.525" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="345.208" cy="77.683" r="4.85518" transform="rotate(-90 345.208 77.683)" fill="white" />
                    <circle classname={styles.hidden} cx="694.78" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1048.72" cy="441.855" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="179.641" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="529.215" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="709.346" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1281.77" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="753.043" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="165.076" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="616.608" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1306.53" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="500.084" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1077.85" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1350.23" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1223.51" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1291.97" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1194.37" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1500.25" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1500.25" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="514.65" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1412.86" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="63.1169" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1466.75" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.47" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="63.1169" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1063.28" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="281.6" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="4.85518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="184.986" cy="19.4206" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1611.92" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1641.05" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1611.92" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="403.469" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1481.32" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="747.698" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.47" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1437.62" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="281.6" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1582.79" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1248.27" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1034.15" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1354.6" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="106.814" cy="441.855" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.961" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="194.207" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1063.28" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="267.035" cy="820.559" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1441.99" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="791.394" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1525.02" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="267.035" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="184.986" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="281.6" cy="805.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1219.14" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1364.8" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1626.49" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="645.739" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="155.855" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="301.51" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="572.912" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="767.608" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="849.656" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="272.38" cy="19.4206" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="243.248" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="92.2478" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1466.75" cy="48.5518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1568.22" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="427.256" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="971.036" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1146.31" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1471.12" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="77.6829" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1238.07" cy="791.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1626.49" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1131.75" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="971.036" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1466.75" cy="92.2484" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1485.69" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="636.518" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="587.477" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1267.2" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1219.14" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1325.46" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="791.394" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1500.25" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="864.222" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="747.698" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1427.42" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1029.79" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1088.05" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="912.774" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1044.35" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="184.986" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="674.87" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="48.5515" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="680.215" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1048.72" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="665.649" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1121.55" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="418.035" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="529.215" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="543.781" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="709.346" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1146.31" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1160.88" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="791.394" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1194.37" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1452.19" cy="77.6829" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="403.469" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="631.174" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1150.68" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="106.814" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1452.19" cy="48.5518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="135.945" cy="441.855" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="651.084" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1291.97" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="519.994" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1092.42" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="912.774" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="636.518" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="359.773" cy="135.945" r="4.85518" transform="rotate(-90 359.773 135.945)" fill="white" />
                    <circle classname={styles.hidden} cx="403.469" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="796.74" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.34" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="912.774" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="194.207" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1063.28" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="578.256" cy="179.642" r="4.85518" fill="white" />
<circle classname={styles.hidden} cx="92.2478" cy="441.855" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="63.1174" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="607.386" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1063.28" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="228.683" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="359.773" cy="48.5519" r="4.85518" transform="rotate(-90 359.773 48.5519)" fill="white" />
                    <circle classname={styles.hidden} cx="170.421" cy="63.1174" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="558.345" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1437.62" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1383.73" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="194.207" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1485.69" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="753.043" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1291.97" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1208.94" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="762.263" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="660.304" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="805.961" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1034.15" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1248.27" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1019.59" cy="456.421" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1539.58" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="607.386" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="971.036" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="820.559" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="398.125" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1179.81" cy="805.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1655.62" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="121.379" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="849.656" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="179.641" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1398.29" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="339.862" cy="791.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="388.904" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="912.774" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1582.79" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="689.435" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1150.68" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1073.49" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1641.05" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1291.97" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1179.81" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1495.89" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="621.953" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1558.51" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="194.207" cy="456.421" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1179.81" cy="820.559" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1034.15" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="19.4206" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1325.46" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1597.36" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1160.88" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="383.559" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1310.9" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="354.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="184.986" cy="92.2484" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="485.517" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="621.953" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="884.133" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1102.62" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="485.517" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1350.23" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1558.51" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="170.421" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="403.469" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1048.72" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.34" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1335.66" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="325.298" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="500.084" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1248.27" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1335.66" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1369.16" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="267.035" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1291.97" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1044.35" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="680.215" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1354.6" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1427.42" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="106.814" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="660.304" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="505.428" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="150.51" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="398.125" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1466.75" cy="77.6829" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1034.15" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="121.379" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="354.428" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.209" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="170.421" cy="48.5518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="325.298" cy="791.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="505.428" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="616.608" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1383.73" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1102.62" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="286.945" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="689.435" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="77.6833" cy="456.421" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="519.994" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="490.863" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="77.6833" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1015.22" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="345.208" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="971.036" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1077.85" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.961" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1354.6" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1088.05" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="776.829" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1058.92" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1048.72" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="912.774" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="869.567" cy="296.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="77.6833" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.209" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1471.12" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="441.822" cy="674.904" r="4.85518" transform="rotate(-90 441.822 674.904)" fill="white" />
                    <circle classname={styles.hidden} cx="631.174" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1398.29" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="243.248" cy="4.85518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1194.37" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="607.386" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1219.14" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1306.53" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1582.79" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="92.2478" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="325.298" cy="762.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1000.66" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="150.51" cy="441.855" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="243.248" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1485.69" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="805.961" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="461.732" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="252.469" cy="791.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1291.97" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="651.084" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="155.855" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1481.32" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1015.22" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1354.6" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="77.6833" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="63.1174" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1437.62" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="388.904" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1034.15" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="791.394" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1412.86" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1165.24" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1350.23" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1252.64" cy="805.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="855.002" cy="325.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="339.862" cy="805.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1175.44" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1441.99" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="165.076" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="534.559" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1383.73" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1466.75" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="543.781" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="165.076" cy="456.421" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="281.6" cy="747.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1500.25" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="301.51" cy="19.4206" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1179.81" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1408.49" cy="77.6829" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1437.62" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="694.78" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="106.814" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="723.912" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1048.72" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1077.85" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1495.89" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="135.945" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1398.29" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="170.421" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="820.525" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1117.18" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="121.379" cy="456.421" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1350.23" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="519.994" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.961" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1471.12" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1077.85" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1146.31" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1514.82" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="325.298" cy="805.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="782.174" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1568.22" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="747.698" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="636.518" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1034.15" cy="456.421" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1441.99" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="912.774" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1019.59" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="228.683" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1354.6" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1029.79" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="92.2478" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1102.62" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="281.6" cy="762.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1048.72" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="155.855" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="534.559" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="228.683" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="971.036" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="368.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1481.32" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="330.641" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="418.035" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="485.517" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1238.07" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="723.912" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="738.477" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="796.74" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1354.6" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="92.2478" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="179.641" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="281.6" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="4.85518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="208.772" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="281.6" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="485.517" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1146.31" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="820.525" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="432.601" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="208.772" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.47" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="529.215" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1291.97" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1063.28" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="776.829" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="791.394" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="689.435" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="500.084" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1029.79" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="805.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1102.62" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1510.45" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="339.862" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="150.51" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1626.49" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="791.394" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="165.076" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1029.79" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1179.81" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1500.25" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1325.46" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1452.19" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.961" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="723.912" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1582.79" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1481.32" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="418.035" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="403.469" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.47" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="558.345" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1063.28" cy="456.421" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="762.263" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="674.87" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="165.076" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.34" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1233.71" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="762.263" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1281.77" cy="747.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1485.69" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="447.166" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1015.22" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="77.6833" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="849.656" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1485.69" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1058.92" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1233.71" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="179.641" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="534.559" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1398.29" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1398.29" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1423.06" cy="92.2484" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="281.6" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1529.38" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="155.855" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1582.79" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1267.2" cy="747.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1369.16" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="388.904" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="170.421" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1029.79" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="48.5515" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1048.72" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1044.35" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1252.64" cy="762.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="514.65" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="92.2478" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="543.781" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1088.05" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="412.69" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="418.035" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1529.38" cy="747.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="884.133" cy="325.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1034.15" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="150.51" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1325.46" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="776.829" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="529.215" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="631.174" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="194.207" cy="441.855" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="505.428" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1019.59" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1568.22" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="359.773" cy="121.38" r="4.85518" transform="rotate(-90 359.773 121.38)" fill="white" />
                    <circle classname={styles.hidden} cx="665.649" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1102.62" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="636.518" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="883.643" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="519.994" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="184.986" cy="48.5518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="689.435" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="587.477" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.47" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1000.66" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="884.133" cy="339.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1325.46" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="723.912" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="689.435" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="48.5515" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1150.68" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1136.11" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="912.774" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="228.683" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="432.601" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.961" cy="296.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="796.74" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="776.829" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1611.92" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1441.99" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1398.29" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1379.36" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1437.62" cy="92.2484" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1117.18" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1252.64" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="747.698" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="257.814" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="441.822" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="301.51" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="155.855" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="776.829" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.34" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1369.16" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1296.33" cy="805.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1048.72" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1233.71" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1121.55" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1238.07" cy="762.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="485.517" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1466.75" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1611.92" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="48.5515" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="121.379" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="796.74" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="281.6" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1437.62" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1267.2" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="645.739" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1015.22" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="339.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1194.37" cy="762.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="500.084" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="447.166" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="272.38" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="505.428" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1048.72" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="767.608" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1655.62" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="330.641" cy="4.85518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1364.8" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="738.477" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1029.79" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="971.036" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1223.51" cy="805.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="165.076" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1165.24" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="840.435" cy="354.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="432.601" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="723.912" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.47" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.47" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1296.33" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.961" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="689.435" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="267.035" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="106.814" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1165.24" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="92.2478" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1466.75" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="208.772" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1354.6" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="19.4206" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1310.9" cy="762.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1248.27" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1160.88" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1310.9" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1510.45" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="77.6833" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="602.043" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1655.62" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="412.69" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="281.6" cy="791.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="578.256" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="709.346" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="150.51" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="636.518" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="368.994" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="432.601" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="665.649" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1281.77" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="549.125" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="150.51" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1296.33" cy="762.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="835.091" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1325.46" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="674.87" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1267.2" cy="820.559" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1310.9" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1048.72" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1281.77" cy="805.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1088.05" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1223.51" cy="762.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="941.905" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1325.46" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="301.51" cy="4.85518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="820.525" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="456.386" cy="689.469" r="4.85518" transform="rotate(-90 456.386 689.469)" fill="white" />
                    <circle classname={styles.hidden} cx="1452.19" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1321.1" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="403.469" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1641.05" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.34" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="514.65" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.47" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1423.06" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="368.994" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1058.92" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="519.994" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1000.66" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1252.64" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="63.1169" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="985.601" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1514.82" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="150.51" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="179.641" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="63.1169" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="194.207" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1481.32" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="645.739" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="884.133" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="310.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="631.174" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1310.9" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="368.994" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="767.608" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="529.215" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1310.9" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="529.215" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="782.174" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="77.6833" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="48.5518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="418.035" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1223.51" cy="820.559" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1325.46" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="388.904" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="228.683" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="534.559" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.209" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="456.386" cy="631.207" r="4.85518" transform="rotate(-90 456.386 631.207)" fill="white" />
                    <circle classname={styles.hidden} cx="1369.16" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="616.608" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="325.298" cy="747.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="184.986" cy="77.6829" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="345.208" cy="150.511" r="4.85518" transform="rotate(-90 345.208 150.511)" fill="white" />
                    <circle classname={styles.hidden} cx="912.774" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="971.036" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1310.9" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1019.59" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="48.5515" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="267.035" cy="776.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="135.945" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="753.043" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="179.641" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="534.559" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1277.4" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="170.421" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1073.49" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1582.79" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="208.772" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1626.49" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1262.84" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1248.27" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1543.95" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="820.525" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="165.076" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="121.379" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="243.248" cy="19.4206" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1325.46" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="680.215" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="811.304" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="77.6833" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="461.732" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1131.75" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1000.66" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="194.207" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1398.29" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1383.73" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="985.601" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="689.435" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="330.641" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="330.641" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1077.85" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="121.379" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="272.38" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1597.36" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="485.517" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1248.27" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="48.5515" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="631.174" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="4.85518" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="383.559" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1379.36" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="461.732" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1233.71" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1582.79" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1466.75" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1481.32" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1481.32" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1238.07" cy="776.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="607.386" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1655.62" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1248.27" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1471.12" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1150.68" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1670.18" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1369.16" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="330.641" cy="19.4206" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="170.421" cy="92.2484" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1582.79" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="179.641" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1684.75" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1262.84" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="543.781" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1219.14" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="359.773" cy="77.683" r="4.85518" transform="rotate(-90 359.773 77.683)" fill="white" />
                    <circle classname={styles.hidden} cx="48.5515" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.34" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="135.945" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1427.42" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="106.814" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="179.641" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1529.38" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1485.69" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1277.4" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="791.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1558.51" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="441.822" cy="602.076" r="4.85518" transform="rotate(-90 441.822 602.076)" fill="white" />
                    <circle classname={styles.hidden} cx="1611.92" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="228.683" cy="4.85518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1398.29" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="432.601" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="869.567" cy="325.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="563.691" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="660.304" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="403.469" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="500.084" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1398.29" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="456.386" cy="645.773" r="4.85518" transform="rotate(-90 456.386 645.773)" fill="white" />
                    <circle classname={styles.hidden} cx="447.166" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1398.29" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1194.37" cy="776.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="339.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="835.091" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="849.656" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="447.166" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1267.2" cy="805.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1165.24" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="150.51" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="563.691" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="243.248" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1267.2" cy="791.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="155.855" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1238.07" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="208.772" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1543.95" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="883.643" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="418.035" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1121.55" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="762.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1029.79" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1073.49" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="490.863" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="660.304" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1179.81" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="811.304" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="441.822" cy="587.511" r="4.85518" transform="rotate(-90 441.822 587.511)" fill="white" />
                    <circle classname={styles.hidden} cx="476.297" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1321.1" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1117.18" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1165.24" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1029.79" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1175.44" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="665.649" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1441.99" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1194.37" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1466.75" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1471.12" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="572.912" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="776.829" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1262.84" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="418.035" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="805.961" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1233.71" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1117.18" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1500.25" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="651.084" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="660.304" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1136.11" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="252.469" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1077.85" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="674.87" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1165.24" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="674.87" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="912.774" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1466.75" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.47" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1136.11" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="63.1174" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1500.25" cy="747.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="63.1169" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="835.091" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="733.133" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1296.33" cy="747.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1481.32" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="534.559" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="325.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="92.2478" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1466.75" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="345.208" cy="63.1174" r="4.85518" transform="rotate(-90 345.208 63.1174)" fill="white" />
                    <circle classname={styles.hidden} cx="165.076" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="805.961" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1452.19" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="941.905" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="135.945" cy="456.421" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="835.091" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="267.035" cy="747.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1626.49" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="674.87" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1281.77" cy="820.559" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="762.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="432.601" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="883.643" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1179.81" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1175.44" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1238.07" cy="747.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="179.641" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1150.68" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="447.166" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="534.559" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="427.256" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1146.31" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="106.814" cy="456.421" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="912.774" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1077.85" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1296.33" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="762.263" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="869.567" cy="368.994" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="77.6829" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="155.855" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1077.85" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1015.22" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="941.905" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="184.986" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1321.1" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1466.75" cy="63.1174" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1611.92" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="621.953" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="985.601" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="418.035" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1077.85" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1514.82" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1641.05" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="77.6833" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="48.5515" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1529.38" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1034.15" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1150.68" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="476.297" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1568.22" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="106.814" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="77.6833" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="383.559" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1063.28" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1248.27" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1471.12" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1369.16" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="77.6833" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="645.739" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.47" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1296.33" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1019.59" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1485.69" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1034.15" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="368.994" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1019.59" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1088.05" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="325.298" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="621.953" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="170.421" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="63.1174" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="767.608" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="461.732" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="500.084" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1325.46" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1194.37" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1597.36" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1481.32" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1063.28" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1655.62" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="912.774" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1029.79" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1077.85" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="519.994" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1495.89" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1252.64" cy="747.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="602.043" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="267.035" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="179.641" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="956.47" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="388.904" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1553.66" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1529.38" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="63.1169" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="170.421" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1092.42" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="228.683" cy="19.4206" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1277.4" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="432.601" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="811.304" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1310.9" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="607.386" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="383.559" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1063.28" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="170.421" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="19.4206" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1597.36" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1179.81" cy="762.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="194.207" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1452.19" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1514.82" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="135.945" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1015.22" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="243.248" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1034.15" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1208.94" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="558.345" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="941.905" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="602.043" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="170.421" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="587.477" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="505.428" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1500.25" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1471.12" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.209" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="272.38" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1485.69" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1310.9" cy="747.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1558.51" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="272.38" cy="4.85518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1481.32" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1233.71" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="19.4206" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1179.81" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="170.421" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="674.87" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="485.517" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="179.641" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1412.86" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="447.166" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="674.87" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1248.27" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1321.1" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="884.133" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="805.961" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1019.59" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="223.339" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="63.1174" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="485.517" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="296.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1223.51" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1510.45" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="849.656" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="33.986" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="674.87" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.698" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="694.78" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="339.862" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="316.076" cy="19.4206" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="971.036" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="805.961" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="106.814" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1525.02" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1364.8" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="549.125" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.34" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="301.51" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1117.18" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="500.084" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="709.346" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1369.16" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="505.428" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1655.62" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="762.263" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="325.298" cy="776.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="432.601" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1077.85" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="184.986" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="184.986" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="835.091" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="48.5518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1510.45" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="709.346" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="651.084" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="447.166" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="441.822" cy="689.469" r="4.85518" transform="rotate(-90 441.822 689.469)" fill="white" />
                    <circle classname={styles.hidden} cx="1670.18" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1248.27" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="19.4206" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="519.994" cy="150.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="645.739" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="791.394" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="674.87" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="572.912" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="267.035" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1655.62" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="747.698" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="63.1174" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1267.2" cy="704.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="179.641" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1296.33" cy="776.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1238.07" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="48.5515" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="412.69" cy="689.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1354.6" cy="514.683" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="223.339" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="558.345" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1077.85" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="811.304" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1543.95" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1412.86" cy="500.117" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="738.477" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="368.994" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1208.94" cy="820.559" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="228.683" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="840.435" cy="325.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="155.855" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1514.82" cy="747.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1277.4" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="77.6833" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="651.084" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1281.77" cy="733.166" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="418.035" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="33.986" cy="470.986" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1048.72" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1063.28" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.34" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="325.298" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1238.07" cy="820.559" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="971.036" cy="674.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1452.19" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="811.304" cy="194.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="942.394" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="33.9863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1077.85" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="971.036" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="310.731" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="199.552" cy="92.2484" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1281.77" cy="762.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1296.33" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="776.829" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1412.86" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="791.394" cy="558.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="281.6" cy="776.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1350.23" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1321.1" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="514.65" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1456.55" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="805.961" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1121.55" cy="616.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.83" cy="354.428" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="680.215" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1058.92" cy="281.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="927.34" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="316.076" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="296.166" cy="776.863" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1160.88" cy="179.642" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="33.986" cy="529.248" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1281.77" cy="587.511" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1306.53" cy="267.035" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="840.435" cy="310.732" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="184.986" cy="237.904" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1175.44" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="514.65" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="252.469" cy="762.297" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="747.698" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="913.263" cy="77.6829" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1393.93" cy="92.2484" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1597.36" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="941.905" cy="485.552" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="898.209" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="301.51" cy="135.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="427.256" cy="660.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="92.2478" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="519.994" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1529.38" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1063.28" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="228.683" cy="223.338" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="549.125" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1077.85" cy="572.945" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="214.117" cy="48.5518" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="796.74" cy="208.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="412.69" cy="718.601" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1233.71" cy="252.47" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1641.05" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="150.51" cy="631.207" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1048.72" cy="645.773" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="636.518" cy="165.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="447.166" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="782.174" cy="121.38" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="267.035" cy="602.076" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="1452.19" cy="106.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="412.69" cy="543.814" r="4.85518" fill="white" />
                    <circle classname={styles.hidden} cx="674.87" cy="704.035" r="4.85518" fill="white" />

                </svg>

                :

                <svg ref={svgRef} viewBox="0 0 3185 389" fill="none" xmlns="http://www.w3.org/2000/svg">

                    <circle className={styles.hidden} cx="1330.32" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2995.65" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3010.21" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2437.3" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="237.904" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1645.91" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1082.71" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="19.4206" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1675.04" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1009.88" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3078.19" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2437.3" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="645.739" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2024.61" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2777.16" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1777" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1704.17" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2184.83" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2806.29" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2791.73" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1645.91" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="966.181" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="33.986" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2529.55" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1689.6" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2451.87" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3107.32" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1301.19" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1543.95" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="252.469" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2587.81" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2184.83" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2908.25" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="805.96" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2762.6" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2660.64" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="878.788" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1543.95" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1359.45" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="718.567" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2257.66" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2155.7" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1330.32" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1170.1" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="398.125" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2408.17" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2053.74" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="383.559" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1009.88" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1820.69" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="150.51" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2937.39" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="296.166" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1126.4" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2981.08" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2820.86" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2660.64" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2544.12" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2379.04" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2082.87" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3121.88" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2286.79" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1558.51" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="121.38" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="194.208" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1170.1" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="252.469" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="616.608" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1184.66" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="893.353" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="179.641" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2733.47" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="121.38" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="500.084" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="135.945" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2820.86" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2646.07" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2864.56" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2272.23" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2573.25" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1529.38" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2286.79" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2995.65" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1835.26" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2170.27" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2437.3" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="33.986" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1272.06" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1835.26" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1272.06" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1199.23" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2762.6" cy="383.559" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2228.53" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1257.49" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2689.77" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1820.69" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1184.66" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1762.43" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1573.08" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2849.99" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2981.08" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2806.29" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2660.64" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2660.64" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1315.75" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2408.17" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="704.002" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="893.353" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3010.21" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1908.09" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2675.21" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1602.21" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2981.08" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="660.305" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2558.68" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2170.27" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2777.16" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1097.27" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="77.6828" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3053.91" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="704.002" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2024.61" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1995.48" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="980.747" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1558.51" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="165.076" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="849.657" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="412.691" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2437.3" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2544.12" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2155.7" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1140.97" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2879.12" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="325.297" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2981.08" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="878.788" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1558.51" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1602.21" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2257.66" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="558.346" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2631.51" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="194.208" cy="48.5518" r="4.85518" transform="rotate(-90 194.208 48.5518)" fill="white" />
                    <circle className={styles.hidden} cx="878.788" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3107.32" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3136.45" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2558.68" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2573.25" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2408.17" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1558.51" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1573.08" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1777" cy="383.559" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2966.52" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="281.601" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2995.65" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2995.65" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="237.904" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1718.74" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1660.47" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2053.74" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1543.95" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3092.75" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2573.25" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2529.55" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2544.12" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2529.55" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2820.86" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2097.44" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1951.78" cy="179.642" r="4.85518" transform="rotate(-90 1951.78 179.642)" fill="white" />
                    <circle className={styles.hidden} cx="1024.44" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1330.32" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="849.657" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="179.641" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2748.03" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2272.23" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2437.3" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1199.23" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2981.08" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="4.85518" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2660.64" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="995.313" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3121.88" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1645.91" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="92.2482" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="92.2482" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1660.47" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2422.74" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="572.912" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1039.01" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="893.353" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2966.52" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2631.51" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1995.48" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="77.6828" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2646.07" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1616.78" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2422.74" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2039.18" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1980.91" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="529.215" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3063.62" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1951.78" cy="237.904" r="4.85518" transform="rotate(-90 1951.78 237.904)" fill="white" />
                    <circle className={styles.hidden} cx="2675.21" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1675.04" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="951.616" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2573.25" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1762.43" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1777" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3024.78" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="995.313" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1631.34" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2762.6" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="937.05" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2466.43" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="398.125" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1068.14" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="208.773" cy="135.945" r="4.85518" transform="rotate(-90 208.773 135.945)" fill="white" />
                    <circle className={styles.hidden} cx="1257.49" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1616.78" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1675.04" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="718.567" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1645.91" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2864.56" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="296.166" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1344.89" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="237.904" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2660.64" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1616.78" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="951.616" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1980.91" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2286.79" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1660.47" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1009.88" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="966.181" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1602.21" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2286.79" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2558.68" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2733.47" cy="383.559" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="92.2482" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2806.29" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3078.19" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3165.58" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="339.863" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1315.75" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="383.559" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3136.45" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1704.17" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2243.09" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="529.215" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="252.469" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2286.79" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2791.73" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2529.55" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2704.34" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1315.75" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2748.03" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="733.133" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1893.52" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="529.215" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1140.97" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1922.65" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1922.65" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1111.84" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="296.166" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="33.986" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2393.61" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1272.06" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2257.66" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2024.61" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1849.82" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1864.39" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="281.601" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2573.25" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2922.82" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2646.07" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="602.043" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="849.657" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1602.21" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1631.34" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1689.6" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2243.09" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="310.732" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="864.222" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2330.49" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="733.133" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="194.208" cy="150.511" r="4.85518" transform="rotate(-90 194.208 150.511)" fill="white" />
                    <circle className={styles.hidden} cx="1616.78" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="354.428" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="689.436" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="718.567" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3107.32" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1009.88" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2514.98" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="252.469" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2733.47" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1908.09" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="194.208" cy="121.38" r="4.85518" transform="rotate(-90 194.208 121.38)" fill="white" />
                    <circle className={styles.hidden} cx="718.567" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3165.58" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1645.91" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="689.436" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="427.256" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="660.305" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1573.08" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1082.71" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1068.14" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1849.82" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2573.25" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2762.6" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1631.34" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="529.215" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1762.43" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="485.518" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="805.96" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1228.36" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2126.57" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1762.43" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="572.912" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2718.9" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2762.6" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1602.21" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="296.166" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1573.08" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="354.428" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="92.2482" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1747.87" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1097.27" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2112" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2558.68" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1762.43" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2995.65" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2893.69" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="995.313" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="77.6828" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2733.47" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2155.7" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="616.608" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2024.61" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2981.08" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2675.21" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2141.14" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2112" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3151.01" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2806.29" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2718.9" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2864.56" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1315.75" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1820.69" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1704.17" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2704.34" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2908.25" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="281.601" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1704.17" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2330.49" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2981.08" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2718.9" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2646.07" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1995.48" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2748.03" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="864.222" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2573.25" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1878.96" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="252.469" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="281.601" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1864.39" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="572.912" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1777" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1097.27" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="864.222" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2733.47" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2408.17" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="267.035" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3151.01" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1980.91" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1777" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2675.21" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="718.567" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2675.21" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1602.21" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2893.69" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="805.96" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2908.25" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="660.305" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1170.1" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="966.181" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1024.44" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2024.61" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1170.1" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="383.559" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="296.166" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="4.85518" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1199.23" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="645.739" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="922.485" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2879.12" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1082.71" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2879.12" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1082.71" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2466.43" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2616.94" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1762.43" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2981.08" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2301.36" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1155.53" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1301.19" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1675.04" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2660.64" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="296.166" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="543.78" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2544.12" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1558.51" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="645.739" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1747.87" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1777" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2301.36" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1777" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2481" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2315.92" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1762.43" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2466.43" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2408.17" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2301.36" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2010.05" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2893.69" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1878.96" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1213.8" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="194.208" cy="77.6829" r="4.85518" transform="rotate(-90 194.208 77.6829)" fill="white" />
                    <circle className={styles.hidden} cx="1660.47" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1660.47" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2529.55" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2082.87" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1645.91" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="33.986" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1068.14" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="194.208" cy="106.814" r="4.85518" transform="rotate(-90 194.208 106.814)" fill="white" />
                    <circle className={styles.hidden} cx="1631.34" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2849.99" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2514.98" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2010.05" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1082.71" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2451.87" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="500.084" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2408.17" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1631.34" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="689.436" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2184.83" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3039.34" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="893.353" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="660.305" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1660.47" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1878.96" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="631.174" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="354.428" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="4.85518" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="383.559" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3107.32" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2301.36" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2544.12" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="602.043" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="922.485" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1908.09" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1922.65" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2544.12" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1199.23" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="77.6828" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2544.12" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2466.43" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1330.32" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1587.64" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2330.49" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="704.002" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2379.04" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1082.71" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1777" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1301.19" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3078.19" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="194.208" cy="135.945" r="4.85518" transform="rotate(-90 194.208 135.945)" fill="white" />
                    <circle className={styles.hidden} cx="208.773" cy="77.6829" r="4.85518" transform="rotate(-90 208.773 77.6829)" fill="white" />
                    <circle className={styles.hidden} cx="2272.23" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1529.38" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="310.732" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1529.38" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2791.73" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="529.215" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1835.26" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="733.133" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="19.4206" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1500.25" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2879.12" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="602.043" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3151.01" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="805.96" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1835.26" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1068.14" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2544.12" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="179.641" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1082.71" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1344.89" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2748.03" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="500.084" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2820.86" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3136.45" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1344.89" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="733.133" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1286.62" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2359.62" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2170.27" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1762.43" cy="383.559" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="572.912" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2330.49" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2184.83" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2422.74" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="310.732" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="718.567" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1587.64" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2631.51" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2704.34" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2451.87" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1995.48" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3092.75" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1820.69" cy="383.559" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="135.945" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1500.25" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="296.166" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2966.52" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="558.346" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="135.945" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="937.05" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1660.47" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3039.34" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="645.739" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1111.84" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1922.65" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3165.58" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1140.97" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="121.38" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2257.66" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1616.78" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3121.88" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2777.16" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1024.44" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1388.58" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="470.952" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1024.44" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1301.19" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2097.44" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="689.436" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="470.952" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="689.436" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3024.78" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="849.657" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3121.88" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1762.43" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2981.08" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="456.387" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1301.19" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="135.945" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1573.08" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2806.29" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2451.87" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="354.428" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1777" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2422.74" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="631.174" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1587.64" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2791.73" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1558.51" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="937.05" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1272.06" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2330.49" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2631.51" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="980.747" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2024.61" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1543.95" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1558.51" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2301.36" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2272.23" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="77.6828" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1995.48" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2529.55" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1747.87" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2272.23" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2893.69" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1587.64" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1242.93" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="704.002" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2893.69" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1301.19" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1762.43" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="893.353" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="980.747" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2068.31" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="77.6828" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1675.04" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2082.87" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2141.14" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2514.98" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="514.649" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1170.1" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2922.82" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1024.44" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2849.99" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2849.99" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="165.076" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="19.4206" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2359.62" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2184.83" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2616.94" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3078.19" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1330.32" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1631.34" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1344.89" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1024.44" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1587.64" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="412.691" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="19.4206" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3092.75" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1777" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="354.428" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2704.34" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2141.14" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1689.6" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="470.952" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="864.222" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2660.64" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2082.87" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2481" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2879.12" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2286.79" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2893.69" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2544.12" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1704.17" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="704.002" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1097.27" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2422.74" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1315.75" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1689.6" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="805.96" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="485.518" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="849.657" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2777.16" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1587.64" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2908.25" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="514.649" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1849.82" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1937.22" cy="165.076" r="4.85518" transform="rotate(-90 1937.22 165.076)" fill="white" />
                    <circle className={styles.hidden} cx="1301.19" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1359.45" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="33.986" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2922.82" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="252.469" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3136.45" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="325.297" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2806.29" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2529.55" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1199.23" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1097.27" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="267.035" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="631.174" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1631.34" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2893.69" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1587.64" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1660.47" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1878.96" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2937.39" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2820.86" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1315.75" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="893.353" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2777.16" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3092.75" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1009.88" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2879.12" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2170.27" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="383.559" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2451.87" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="33.986" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1111.84" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="368.994" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2437.3" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="33.986" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2631.51" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1573.08" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="689.436" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1893.52" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2301.36" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="849.657" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1849.82" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1937.22" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1082.71" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="572.912" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="4.85518" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2422.74" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1908.09" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1689.6" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1068.14" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="77.6828" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2039.18" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2849.99" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1631.34" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1645.91" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2558.68" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1558.51" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="937.05" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2922.82" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1097.27" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="121.38" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2155.7" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2573.25" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2748.03" cy="383.559" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="267.035" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3063.62" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2330.49" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2437.3" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1514.82" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2631.51" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1359.45" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2514.98" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2082.87" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2616.94" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="878.788" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1558.51" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="208.773" cy="150.511" r="4.85518" transform="rotate(-90 208.773 150.511)" fill="white" />
                    <circle className={styles.hidden} cx="1272.06" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2112" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2748.03" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2864.56" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2466.43" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1645.91" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="267.035" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="33.986" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="33.986" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2039.18" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2213.96" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1097.27" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2733.47" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="33.986" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="616.608" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2937.39" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1820.69" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="368.994" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3107.32" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2733.47" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="310.732" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="106.814" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="33.986" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2451.87" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="267.035" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="587.477" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="893.353" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1184.66" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="412.691" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="922.485" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1301.19" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2777.16" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1587.64" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2558.68" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="383.559" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="456.387" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3092.75" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3024.78" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2082.87" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1155.53" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2514.98" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2481" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="383.559" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="966.181" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="325.297" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2879.12" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1330.32" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="587.477" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="660.305" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2286.79" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1140.97" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2437.3" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2155.7" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2864.56" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2112" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1573.08" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="383.559" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1660.47" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1689.6" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2243.09" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2514.98" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="383.559" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2408.17" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2806.29" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2272.23" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2330.49" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2330.49" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3039.34" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3121.88" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2243.09" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2995.65" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1616.78" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2544.12" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2631.51" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2345.05" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2529.55" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1616.78" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3121.88" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="878.788" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1068.14" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2529.55" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1140.97" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1272.06" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="500.084" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1286.62" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1213.8" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1689.6" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="281.601" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3078.19" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2437.3" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3092.75" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2937.39" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="907.919" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1704.17" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1097.27" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2849.99" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1602.21" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2558.68" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2908.25" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="208.773" cy="48.5518" r="4.85518" transform="rotate(-90 208.773 48.5518)" fill="white" />
                    <circle className={styles.hidden} cx="2748.03" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2908.25" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2704.34" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2616.94" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2748.03" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="267.035" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="849.657" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2257.66" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2704.34" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1286.62" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2675.21" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2791.73" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1587.64" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="456.387" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1675.04" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="33.986" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="805.96" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1864.39" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="33.986" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1111.84" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1631.34" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3092.75" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2514.98" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2141.14" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2039.18" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="237.904" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="660.305" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2257.66" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2675.21" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2660.64" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2024.61" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="310.732" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2155.7" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2689.77" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1155.53" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="849.657" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1286.62" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2689.77" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2966.52" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="179.641" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2762.6" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="368.994" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1937.22" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2466.43" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1937.22" cy="252.47" r="4.85518" transform="rotate(-90 1937.22 252.47)" fill="white" />
                    <circle className={styles.hidden} cx="1704.17" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2762.6" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="514.649" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2820.86" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2408.17" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1359.45" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2631.51" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1184.66" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3034.49" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1155.53" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1272.06" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1301.19" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2422.74" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="150.51" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1937.22" cy="179.642" r="4.85518" transform="rotate(-90 1937.22 179.642)" fill="white" />
                    <circle className={styles.hidden} cx="1543.95" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1082.71" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1689.6" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="77.6828" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="19.4206" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2995.65" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2243.09" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3024.78" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1126.4" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2777.16" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1849.82" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="645.739" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2053.74" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1747.87" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1184.66" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2864.56" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2514.98" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="543.78" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="616.608" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1980.91" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1922.65" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="368.994" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="179.641" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="19.4206" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="19.4206" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2718.9" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1228.36" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1893.52" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2791.73" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2762.6" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2451.87" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1835.26" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1762.43" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1718.74" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1111.84" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="310.732" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3010.21" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2514.98" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1820.69" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1272.06" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3092.75" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2864.56" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="237.904" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1009.88" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2257.66" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1689.6" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2616.94" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2286.79" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2286.79" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1315.75" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2558.68" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1980.91" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1573.08" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1645.91" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1893.52" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1514.82" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1777" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2529.55" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="4.85518" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="704.002" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1543.95" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="645.739" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2286.79" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2451.87" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3010.21" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3078.19" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1820.69" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1315.75" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="937.05" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="106.814" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2345.05" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1039.01" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1660.47" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="19.4206" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1718.74" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2437.3" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="296.166" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="645.739" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2315.92" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2466.43" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2908.25" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="907.919" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="150.51" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="907.919" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2718.9" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3136.45" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="150.51" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3121.88" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1777" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2170.27" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2864.56" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1213.8" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1286.62" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2422.74" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2466.43" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1645.91" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1257.49" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2257.66" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="645.739" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1126.4" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2466.43" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2529.55" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2010.05" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2558.68" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1344.89" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="805.96" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1747.87" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1315.75" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1344.89" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3092.75" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3078.19" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3010.21" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2272.23" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2286.79" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2966.52" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3010.21" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2126.57" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2544.12" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="310.732" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="398.125" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1631.34" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1908.09" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2879.12" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2155.7" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2573.25" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="980.747" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="587.477" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2408.17" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3024.78" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2573.25" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3151.01" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2558.68" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2286.79" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1820.69" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="412.691" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1689.6" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2806.29" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3024.78" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2544.12" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3151.01" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="995.313" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1543.95" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="907.919" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3107.32" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="878.788" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1330.32" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="77.6828" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="325.297" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2243.09" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2126.57" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2228.53" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2184.83" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2451.87" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="616.608" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2646.07" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="194.208" cy="92.2486" r="4.85518" transform="rotate(-90 194.208 92.2486)" fill="white" />
                    <circle className={styles.hidden} cx="252.469" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2849.99" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2422.74" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1820.69" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1330.32" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2879.12" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="485.518" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1111.84" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1213.8" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="864.222" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1213.8" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2849.99" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2451.87" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1747.87" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2010.05" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3024.78" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1009.88" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3049.05" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3092.75" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2422.74" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="470.952" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2228.53" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="864.222" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2481" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2573.25" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="383.559" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="543.78" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1835.26" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="194.208" cy="63.1172" r="4.85518" transform="rotate(-90 194.208 63.1172)" fill="white" />
                    <circle className={styles.hidden} cx="718.567" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2422.74" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="980.747" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1126.4" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2422.74" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="937.05" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1762.43" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1937.22" cy="150.511" r="4.85518" transform="rotate(-90 1937.22 150.511)" fill="white" />
                    <circle className={styles.hidden} cx="1558.51" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="907.919" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3010.21" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2558.68" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="543.78" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="281.601" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2514.98" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2864.56" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1558.51" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1747.87" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="631.174" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2806.29" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="500.084" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1140.97" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="427.256" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1199.23" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="805.96" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2286.79" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2408.17" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="106.814" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="398.125" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1082.71" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1573.08" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="165.076" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2791.73" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1689.6" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="893.353" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2660.64" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2170.27" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2039.18" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="33.986" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="907.919" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1514.82" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2748.03" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2646.07" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1660.47" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="4.85518" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2315.92" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="645.739" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="77.6828" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2437.3" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3010.21" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2097.44" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2393.61" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="456.387" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2393.61" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1893.52" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1024.44" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1980.91" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="106.814" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3180.14" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2422.74" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="805.96" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="500.084" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2864.56" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1558.51" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1514.82" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2689.77" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="208.773" cy="106.814" r="4.85518" transform="rotate(-90 208.773 106.814)" fill="white" />
                    <circle className={styles.hidden} cx="1645.91" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2286.79" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2286.79" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2422.74" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="339.863" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2864.56" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2849.99" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2762.6" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3107.32" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2466.43" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1645.91" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2908.25" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="4.85518" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1301.19" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2272.23" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2272.23" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="485.518" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="485.518" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2675.21" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3180.14" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1777" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1951.78" cy="150.511" r="4.85518" transform="rotate(-90 1951.78 150.511)" fill="white" />
                    <circle className={styles.hidden} cx="339.863" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2068.31" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1068.14" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1359.45" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1330.32" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2981.08" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="325.297" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2112" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1529.38" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="893.353" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3034.49" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="150.51" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2806.29" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3078.19" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="602.043" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="602.043" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1374.02" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1301.19" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1762.43" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1747.87" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2514.98" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2529.55" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="980.747" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3121.88" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="310.732" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="325.297" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2437.3" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="92.2482" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2995.65" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1864.39" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1543.95" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2777.16" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="267.035" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2514.98" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1675.04" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3107.32" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2558.68" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="995.313" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1315.75" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2806.29" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2616.94" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2437.3" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2437.3" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1820.69" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2660.64" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2631.51" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="849.657" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2879.12" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2315.92" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2777.16" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="208.773" cy="63.1172" r="4.85518" transform="rotate(-90 208.773 63.1172)" fill="white" />
                    <circle className={styles.hidden} cx="1995.48" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1374.02" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="456.387" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="543.78" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2437.3" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="179.641" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2243.09" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2791.73" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1660.47" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1631.34" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1330.32" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="631.174" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1082.71" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2820.86" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="412.691" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2053.74" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1170.1" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2529.55" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2646.07" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2893.69" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2806.29" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2301.36" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1286.62" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1704.17" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1286.62" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1155.53" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="19.4206" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2849.99" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1675.04" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2272.23" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="543.78" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2466.43" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3092.75" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2514.98" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2573.25" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2529.55" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1980.91" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2141.14" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1126.4" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3107.32" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="19.4206" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2879.12" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2466.43" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1908.09" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="150.51" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1878.96" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1359.45" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3024.78" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="514.649" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="252.469" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="485.518" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3165.58" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2631.51" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="92.2482" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2558.68" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2286.79" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1951.78" cy="194.207" r="4.85518" transform="rotate(-90 1951.78 194.207)" fill="white" />
                    <circle className={styles.hidden} cx="1170.1" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1937.22" cy="208.773" r="4.85518" transform="rotate(-90 1937.22 208.773)" fill="white" />
                    <circle className={styles.hidden} cx="514.649" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2529.55" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2272.23" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1645.91" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2966.52" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="267.035" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="660.305" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="354.428" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1616.78" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3063.62" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1587.64" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1573.08" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="616.608" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2762.6" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="165.076" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="267.035" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2010.05" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="951.616" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="398.125" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2126.57" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2689.77" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="951.616" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2777.16" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3063.62" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="805.96" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1675.04" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2733.47" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="602.043" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1616.78" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="704.002" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2704.34" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2514.98" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="150.51" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3107.32" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="281.601" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1689.6" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2981.08" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1762.43" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="558.346" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="179.641" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2345.05" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2966.52" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="543.78" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2558.68" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2995.65" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="4.85518" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="878.788" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3107.32" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="470.952" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2573.25" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1820.69" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3039.34" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2849.99" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2631.51" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2039.18" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="660.305" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="237.904" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1573.08" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="733.133" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2544.12" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3121.88" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1082.71" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1140.97" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3053.91" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1602.21" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1893.52" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1820.69" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="660.305" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="470.952" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="529.215" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="354.428" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="864.222" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1097.27" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2272.23" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="995.313" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="500.084" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="252.469" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2301.36" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2315.92" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="733.133" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1097.27" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="281.601" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2529.55" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1573.08" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1602.21" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1602.21" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3078.19" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="470.952" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2646.07" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="733.133" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1126.4" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="864.222" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1762.43" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1315.75" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2544.12" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2155.7" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1922.65" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2068.31" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1301.19" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2170.27" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="368.994" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2466.43" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3092.75" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1631.34" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3039.34" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="500.084" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1286.62" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2170.27" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1097.27" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="922.485" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2466.43" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="893.353" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="237.904" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2126.57" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2155.7" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2481" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1344.89" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2558.68" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1009.88" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3063.62" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2675.21" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="980.747" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1184.66" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2301.36" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2466.43" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2393.61" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1587.64" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="805.96" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2660.64" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="733.133" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1995.48" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1995.48" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="966.181" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="922.485" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3136.45" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2718.9" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="980.747" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2272.23" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="252.469" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1704.17" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1602.21" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1587.64" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3024.78" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2408.17" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2675.21" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2112" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="398.125" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2573.25" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2053.74" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2981.08" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1616.78" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1009.88" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1675.04" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3151.01" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3024.78" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="33.986" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3053.91" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="893.353" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1514.82" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1097.27" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1155.53" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="77.6828" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2243.09" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="922.485" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2893.69" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2689.77" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="456.387" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2733.47" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2481" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2718.9" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1315.75" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2849.99" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2301.36" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2529.55" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="19.4206" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2170.27" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="805.96" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="19.4206" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1893.52" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1155.53" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2777.16" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="165.076" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2097.44" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2879.12" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2820.86" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2733.47" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1514.82" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2573.25" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2272.23" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="106.814" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2660.64" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="165.076" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2675.21" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2437.3" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2981.08" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2966.52" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1864.39" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1315.75" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3136.45" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2544.12" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3151.01" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1529.38" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2922.82" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="907.919" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="500.084" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="485.518" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2951.95" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2301.36" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2451.87" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2039.18" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2544.12" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2451.87" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="179.641" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1704.17" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="645.739" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1777" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1286.62" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1645.91" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="995.313" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2733.47" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2675.21" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2704.34" cy="383.559" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="907.919" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="33.986" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="893.353" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2748.03" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1777" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1660.47" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2437.3" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1529.38" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="733.133" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2141.14" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2573.25" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3107.32" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="937.05" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="19.4206" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1864.39" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2864.56" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="4.85518" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="237.904" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2024.61" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="252.469" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1602.21" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2587.81" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3024.78" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1631.34" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1315.75" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1951.78" cy="165.076" r="4.85518" transform="rotate(-90 1951.78 165.076)" fill="white" />
                    <circle className={styles.hidden} cx="485.518" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1922.65" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="878.788" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1359.45" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2257.66" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2879.12" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2966.52" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="310.732" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2315.92" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2820.86" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="77.6828" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2675.21" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2393.61" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="33.986" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="864.222" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1301.19" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="718.567" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1301.19" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2315.92" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2359.62" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1820.69" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1543.95" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1301.19" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2573.25" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2616.94" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2345.05" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3107.32" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2408.17" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2689.77" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1675.04" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2893.69" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1675.04" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="4.85518" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="398.125" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="470.952" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1558.51" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2010.05" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2558.68" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="558.346" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="616.608" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2806.29" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2646.07" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1213.8" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1068.14" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="296.166" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2213.96" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2272.23" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="602.043" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="135.945" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2616.94" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1995.48" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2806.29" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2777.16" cy="383.559" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="252.469" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2039.18" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2451.87" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1689.6" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1543.95" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1587.64" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="733.133" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3010.21" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1344.89" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2791.73" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1388.58" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2806.29" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2951.95" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2966.52" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3121.88" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2097.44" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="92.2482" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="383.559" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2762.6" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2393.61" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2184.83" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2068.31" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1587.64" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1660.47" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2791.73" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2393.61" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1097.27" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1315.75" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2184.83" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1777" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2762.6" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2646.07" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1286.62" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2689.77" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3063.62" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="980.747" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2010.05" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1777" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2544.12" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="77.6828" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="368.994" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2864.56" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1359.45" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1660.47" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3092.75" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3010.21" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1272.06" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2184.83" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2820.86" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3136.45" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1980.91" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="631.174" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1344.89" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1111.84" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1762.43" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1995.48" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="878.788" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="267.035" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1689.6" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="631.174" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2820.86" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1835.26" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="296.166" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1184.66" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="135.945" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2466.43" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2587.81" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2024.61" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2451.87" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="631.174" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2951.95" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1315.75" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="951.616" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="995.313" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2893.69" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="805.96" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3107.32" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="762.263" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2068.31" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="878.788" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3165.58" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="281.601" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1374.02" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2408.17" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3063.62" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1762.43" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="485.518" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1111.84" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2514.98" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3121.88" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2408.17" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="805.96" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1937.22" cy="194.207" r="4.85518" transform="rotate(-90 1937.22 194.207)" fill="white" />
                    <circle className={styles.hidden} cx="1922.65" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="704.002" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="587.477" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2733.47" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1140.97" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="267.035" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1777" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="77.6828" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2170.27" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2068.31" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="281.601" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1689.6" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1689.6" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3151.01" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2437.3" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2689.77" cy="383.559" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2451.87" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1330.32" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="922.485" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2879.12" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="718.567" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1602.21" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1820.69" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1922.65" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="558.346" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2170.27" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2315.92" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="966.181" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2024.61" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1573.08" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2010.05" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1951.78" cy="252.47" r="4.85518" transform="rotate(-90 1951.78 252.47)" fill="white" />
                    <circle className={styles.hidden} cx="3039.34" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="77.6828" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2587.81" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2849.99" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2806.29" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2451.87" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="470.952" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1082.71" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="805.96" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2864.56" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2112" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3121.88" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1718.74" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="325.297" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1573.08" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3063.62" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2408.17" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="281.601" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2864.56" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2704.34" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1388.58" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1675.04" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="179.641" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2820.86" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2879.12" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3092.75" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3063.62" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2184.83" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2126.57" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2286.79" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2893.69" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="92.2482" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="951.616" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1980.91" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="587.477" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="864.222" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1587.64" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1995.48" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1645.91" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="456.387" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2908.25" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3053.91" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2272.23" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="33.986" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1704.17" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="878.788" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1529.38" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2053.74" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2422.74" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1111.84" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="922.485" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2718.9" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="296.166" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="733.133" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="398.125" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2820.86" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2995.65" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2718.9" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1937.22" cy="237.904" r="4.85518" transform="rotate(-90 1937.22 237.904)" fill="white" />
                    <circle className={styles.hidden} cx="2514.98" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="194.208" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="267.035" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3136.45" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="733.133" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2777.16" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1645.91" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2864.56" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="893.353" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="733.133" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1330.32" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1301.19" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="296.166" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1242.93" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2646.07" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="208.773" cy="121.38" r="4.85518" transform="rotate(-90 208.773 121.38)" fill="white" />
                    <circle className={styles.hidden} cx="1616.78" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3136.45" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2257.66" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2675.21" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="704.002" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1301.19" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2718.9" cy="383.559" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2689.77" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1747.87" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1747.87" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2097.44" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2879.12" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="19.4206" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1199.23" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1660.47" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="310.732" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2301.36" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3010.21" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2529.55" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="645.739" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="572.912" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2791.73" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2170.27" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2010.05" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1587.64" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2379.04" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="368.994" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1374.02" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1660.47" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2675.21" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1937.22" cy="223.338" r="4.85518" transform="rotate(-90 1937.22 223.338)" fill="white" />
                    <circle className={styles.hidden} cx="470.952" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="281.601" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1543.95" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1184.66" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="966.181" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="165.076" cy="4.85518" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2243.09" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="267.035" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="194.208" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2908.25" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2451.87" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3024.78" cy="310.732" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2791.73" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2893.69" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2068.31" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1908.09" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1675.04" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1344.89" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="514.649" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1835.26" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="514.649" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2922.82" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2675.21" cy="383.559" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1315.75" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2393.61" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="937.05" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2791.73" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2966.52" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="529.215" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3049.05" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2995.65" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="4.85518" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3180.14" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3078.19" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2689.77" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="470.952" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1777" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1514.82" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="281.601" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="966.181" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2995.65" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2558.68" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="864.222" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="558.346" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="368.994" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3107.32" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2689.77" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1126.4" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2010.05" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="77.6828" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="951.616" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2286.79" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2893.69" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="500.084" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2422.74" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1315.75" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2451.87" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2422.74" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1330.32" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2408.17" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="776.829" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="19.4206" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2893.69" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2937.39" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1529.38" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="63.1174" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2660.64" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="19.4206" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="121.38" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="660.305" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2379.04" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2408.17" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2558.68" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="427.256" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="456.387" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1286.62" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3092.75" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2272.23" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1675.04" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1529.38" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2908.25" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3107.32" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2024.61" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3121.88" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="296.166" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1878.96" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1111.84" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1529.38" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2922.82" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3092.75" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="325.297" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2422.74" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1272.06" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2082.87" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="48.552" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3078.19" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3063.62" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="19.4206" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1791.56" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1543.95" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="558.346" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="747.698" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2748.03" cy="368.994" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1704.17" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2010.05" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="645.739" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="208.773" cy="92.2486" r="4.85518" transform="rotate(-90 208.773 92.2486)" fill="white" />
                    <circle className={styles.hidden} cx="2315.92" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="733.133" cy="296.166" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2791.73" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1747.87" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1068.14" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="951.616" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2704.34" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1951.78" cy="223.338" r="4.85518" transform="rotate(-90 1951.78 223.338)" fill="white" />
                    <circle className={styles.hidden} cx="368.994" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1645.91" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="587.477" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="689.436" cy="354.428" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2966.52" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="805.96" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3049.05" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="791.395" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="907.919" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2558.68" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1820.69" cy="339.863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1068.14" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="878.788" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2529.55" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2126.57" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1937.22" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="572.912" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="995.313" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="500.084" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1199.23" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="587.477" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="354.428" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3107.32" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1675.04" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2379.04" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2379.04" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2893.69" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2097.44" cy="252.469" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2544.12" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1675.04" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="631.174" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2849.99" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1951.78" cy="208.773" r="4.85518" transform="rotate(-90 1951.78 208.773)" fill="white" />
                    <circle className={styles.hidden} cx="2587.81" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="121.38" cy="19.4206" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2053.74" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3107.32" cy="33.9863" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1689.6" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="485.518" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="485.518" cy="208.773" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1616.78" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2616.94" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1170.1" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="907.919" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2345.05" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1820.69" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1747.87" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="514.649" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2315.92" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="339.863" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2466.43" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2243.09" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2791.73" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="4.85518" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1126.4" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2257.66" cy="92.2482" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2820.86" cy="135.945" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2995.65" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="995.313" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="33.986" cy="179.642" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1374.02" cy="267.035" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="907.919" cy="121.38" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3010.21" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3010.21" cy="223.338" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2141.14" cy="106.814" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1155.53" cy="237.904" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1573.08" cy="48.5517" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1806.13" cy="165.076" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2748.03" cy="150.511" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="1660.47" cy="63.1174" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2301.36" cy="281.601" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="456.387" cy="194.207" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="3092.75" cy="77.6828" r="4.85518" fill="white" />
                    <circle className={styles.hidden} cx="2544.12" cy="63.1174" r="4.85518" fill="white" />

                </svg>
            }


        </div>
    );

}

export default Spotlight;
